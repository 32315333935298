import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFolder, faArrowRight, faUser, faClock, faReply, faComment, faCheckCircle, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/PostPage.css';

const PostPage = () => {
  const { postId } = useParams();
  const { darkMode } = useTheme();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState(null);
  
  // حالة التعليقات
  const [comments, setComments] = useState([]);
  const [commenterName, setCommenterName] = useState('');
  const [commentText, setCommentText] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [commentSubmitting, setCommentSubmitting] = useState(false);
  const [replySubmitting, setReplySubmitting] = useState(false);

  // استرجاع المنشور من localStorage
  useEffect(() => {
    const fetchPostData = () => {
      setLoading(true);
      
      // استرجاع المنشور المطلوب
      const savedPosts = localStorage.getItem('posts');
      if (savedPosts) {
        try {
          const parsedPosts = JSON.parse(savedPosts);
          const currentPost = parsedPosts.find(p => p.id === postId);
          
          if (currentPost) {
            setPost(currentPost);
            
            // استرجاع معلومات القسم
            const savedCategories = localStorage.getItem('categories');
            if (savedCategories) {
              const parsedCategories = JSON.parse(savedCategories);
              const postCategory = parsedCategories.find(cat => cat.id === currentPost.category);
              if (postCategory) {
                setCategoryInfo(postCategory);
              }
            }
            
            // إيجاد المنشورات ذات الصلة (من نفس القسم)
            const related = parsedPosts
              .filter(p => p.id !== postId && p.category === currentPost.category && p.status === 'published')
              .slice(0, 3); // أخذ أول 3 منشورات فقط
            
            setRelatedPosts(related);
            
            // زيادة عداد المشاهدات
            const updatedPosts = parsedPosts.map(p => {
              if (p.id === postId) {
                return { ...p, views: (p.views || 0) + 1 };
              }
              return p;
            });
            
            localStorage.setItem('posts', JSON.stringify(updatedPosts));
          }
        } catch (error) {
          console.error('خطأ في استرجاع البيانات من localStorage:', error);
        }
      }
      
      // استرجاع التعليقات للمنشور
      fetchPostComments();
      
      setLoading(false);
    };
    
    fetchPostData();
  }, [postId]);

  // استرجاع التعليقات للمنشور الحالي
  const fetchPostComments = () => {
    try {
      const savedComments = localStorage.getItem('comments');
      if (savedComments) {
        const parsedComments = JSON.parse(savedComments);
        const postComments = parsedComments.filter(comment => comment.postId === postId);
        setComments(postComments);
      }
    } catch (error) {
      console.error('خطأ في استرجاع التعليقات:', error);
    }
  };

  // إضافة مستمع للتغييرات في localStorage
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'posts') {
        const savedPosts = localStorage.getItem('posts');
        if (savedPosts) {
          try {
            const parsedPosts = JSON.parse(savedPosts);
            const updatedPost = parsedPosts.find(p => p.id === postId);
            if (updatedPost) {
              setPost(updatedPost);
            }
          } catch (error) {
            console.error('خطأ في تحديث المنشور:', error);
          }
        }
      } else if (e.key === 'comments') {
        fetchPostComments();
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [postId]);

  // إضافة تعليق جديد
  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (commenterName.trim() === '' || commentText.trim() === '') {
      return;
    }

    // التحقق من حالة التعليقات
    if (post && post.allowComments) {
      alert('التعليقات غير مسموح بها على هذا المنشور');
      return;
    }

    setCommentSubmitting(true);

    // إنشاء تعليق جديد
    const newComment = {
      id: `comment-${Date.now()}`,
      postId: postId,
      name: commenterName,
      content: commentText,
      date: new Date().toISOString(),
      approved: false,
      replies: []
    };

    // إضافة التعليق إلى LocalStorage
    try {
      const savedComments = localStorage.getItem('comments') || '[]';
      const parsedComments = JSON.parse(savedComments);
      const updatedComments = [...parsedComments, newComment];
      localStorage.setItem('comments', JSON.stringify(updatedComments));
      
      // تحديث التعليقات في الصفحة
      setComments(prev => [...prev, newComment]);
      
      // إعادة تعيين الحقول
      setCommenterName('');
      setCommentText('');
      setCommentSubmitting(false);

      // إخطار المستخدم
      alert('تم إرسال تعليقك بنجاح وهو بانتظار الموافقة');
    } catch (error) {
      console.error('خطأ في حفظ التعليق:', error);
      setCommentSubmitting(false);
      alert('حدث خطأ أثناء إرسال التعليق. يرجى المحاولة مرة أخرى.');
    }
  };

  // إضافة رد على تعليق
  const handleReplySubmit = (e, commentId) => {
    e.preventDefault();
    if (commenterName.trim() === '' || replyText.trim() === '') {
      return;
    }

    // التحقق من حالة التعليقات
    if (post && post.allowComments) {
      alert('التعليقات غير مسموح بها على هذا المنشور');
      return;
    }

    setReplySubmitting(true);

    // إنشاء رد جديد
    const newReply = {
      id: `reply-${Date.now()}`,
      name: commenterName,
      content: replyText,
      date: new Date().toISOString(),
      approved: false
    };

    // إضافة الرد إلى التعليق في LocalStorage
    try {
      const savedComments = localStorage.getItem('comments') || '[]';
      const parsedComments = JSON.parse(savedComments);
      
      const updatedComments = parsedComments.map(comment => {
        if (comment.id === commentId) {
          return {
            ...comment,
            replies: [...(comment.replies || []), newReply]
          };
        }
        return comment;
      });
      
      localStorage.setItem('comments', JSON.stringify(updatedComments));
      
      // تحديث التعليقات في الصفحة
      setComments(updatedComments.filter(comment => comment.postId === postId));
      
      // إعادة تعيين الحقول
      setReplyTo(null);
      setReplyText('');
      setReplySubmitting(false);

      // إخطار المستخدم
      alert('تم إرسال ردك بنجاح وهو بانتظار الموافقة');
    } catch (error) {
      console.error('خطأ في حفظ الرد:', error);
      setReplySubmitting(false);
      alert('حدث خطأ أثناء إرسال الرد. يرجى المحاولة مرة أخرى.');
    }
  };

  if (loading) {
    return (
      <div className={`post-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="post-container">
          <div className="loading-spinner"></div>
          <p className="loading-text">جاري تحميل المنشور...</p>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className={`post-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="post-container">
          <div className="post-not-found">
            <h2>المنشور غير موجود</h2>
            <p>عذراً، لم نتمكن من العثور على المنشور الذي تبحث عنه.</p>
            <Link to="/" className="back-to-home">
              <FontAwesomeIcon icon={faArrowRight} className="icon-flip-rtl" />
              العودة إلى الصفحة الرئيسية
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`post-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="post-container">
        <div className="post-header">
          <h1 className="post-title">{post.title}</h1>
          
          <div className="post-meta">
            <div className="post-date">
              <FontAwesomeIcon icon={faCalendarAlt} className="meta-icon" />
              <span>{new Date(post.date).toLocaleDateString('ar-EG')}</span>
            </div>
            
            <div className="post-category">
              <FontAwesomeIcon icon={faFolder} className="meta-icon" />
              <Link to={`/category/${post.category}`}>
                {categoryInfo ? categoryInfo.name : 'قسم غير معروف'}
              </Link>
            </div>
          </div>
          
          {post.author && (
            <div className="post-author">
              بواسطة: <span>{post.author}</span>
            </div>
          )}
        </div>
        
        <div className="post-featured-image">
          <img 
            src={post.image && post.image.trim() !== '' ? post.image : (post.imageUrl && post.imageUrl.trim() !== '' ? post.imageUrl : '/logo.png')}
            alt={post.title} 
            onError={(e) => {
              console.warn('Failed to load featured image:', e.target.src);
              e.target.src = '/logo.png';
              e.target.classList.add('fallback-image');
            }}
          />
        </div>
        
        <div 
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        
        {post.tags && post.tags.length > 0 && (
          <div className="post-tags">
            <span className="tags-label">الوسوم:</span>
            <div className="tags-list">
              {post.tags.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}

        {/* قسم التعليقات */}
        <div className="post-comments-section">
          <h3 className="comments-title">
            <FontAwesomeIcon icon={faComment} className="comments-icon" />
            التعليقات ({comments.filter(c => c.approved).length})
          </h3>
          
          {/* عرض التعليقات الموافق عليها */}
          <div className="comments-list">
            {comments.filter(c => c.approved).length > 0 ? (
              comments
                .filter(c => c.approved)
                .map(comment => (
                  <div key={comment.id} className="comment-item">
                    <div className="comment-header">
                      <div className="comment-author">
                        <FontAwesomeIcon icon={faUser} className="comment-icon" />
                        <span>{comment.name}</span>
                      </div>
                      <div className="comment-date">
                        <FontAwesomeIcon icon={faClock} className="comment-icon" />
                        <span>{new Date(comment.date).toLocaleDateString('ar-EG')}</span>
                      </div>
                    </div>
                    <div className="comment-content">{comment.content}</div>
                    
                    {/* زر الرد على التعليق */}
                    {!post.allowComments && (
                      <button 
                        className="reply-button"
                        onClick={() => {
                          setReplyTo(replyTo === comment.id ? null : comment.id);
                          setReplyText('');
                        }}
                      >
                        <FontAwesomeIcon icon={faReply} />
                        {replyTo === comment.id ? 'إلغاء الرد' : 'الرد على التعليق'}
                      </button>
                    )}
                    
                    {/* نموذج الرد على التعليق */}
                    {replyTo === comment.id && !post.allowComments && (
                      <div className="reply-form">
                        <form onSubmit={(e) => handleReplySubmit(e, comment.id)}>
                          <div className="form-group">
                            <label htmlFor="reply-name">الاسم</label>
                            <input
                              type="text"
                              id="reply-name"
                              value={commenterName}
                              onChange={(e) => setCommenterName(e.target.value)}
                              required
                              placeholder="أدخل اسمك"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="reply-content">الرد</label>
                            <textarea
                              id="reply-content"
                              value={replyText}
                              onChange={(e) => setReplyText(e.target.value)}
                              required
                              placeholder="أكتب ردك هنا"
                              rows="4"
                            ></textarea>
                          </div>
                          <button 
                            type="submit" 
                            className="submit-reply" 
                            disabled={replySubmitting}
                          >
                            {replySubmitting ? 'جاري الإرسال...' : 'إرسال الرد'}
                          </button>
                        </form>
                      </div>
                    )}
                    
                    {/* عرض الردود */}
                    {comment.replies && comment.replies.filter(r => r.approved).length > 0 && (
                      <div className="comment-replies">
                        {comment.replies
                          .filter(r => r.approved)
                          .map(reply => (
                            <div key={reply.id} className="reply-item">
                              <div className="reply-header">
                                <div className="reply-author">
                                  <FontAwesomeIcon icon={faUser} className="reply-icon" />
                                  <span>{reply.name}</span>
                                </div>
                                <div className="reply-date">
                                  <FontAwesomeIcon icon={faClock} className="reply-icon" />
                                  <span>{new Date(reply.date).toLocaleDateString('ar-EG')}</span>
                                </div>
                              </div>
                              <div className="reply-content">{reply.content}</div>
                            </div>
                          ))
                        }
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <div className="no-comments">
                <p>لا توجد تعليقات بعد. كن أول من يعلق!</p>
              </div>
            )}
          </div>
          
          {/* نموذج إضافة تعليق جديد */}
          {post && post.allowComments ? (
            <div className="comments-disabled-message">
              <p>التعليقات غير مسموح بها على هذا المنشور</p>
            </div>
          ) : (
            <div className="add-comment-form">
              <h4>أضف تعليقاً جديداً</h4>
              <form onSubmit={handleCommentSubmit}>
                <div className="form-group">
                  <label htmlFor="commenter-name">الاسم</label>
                  <input
                    type="text"
                    id="commenter-name"
                    value={commenterName}
                    onChange={(e) => setCommenterName(e.target.value)}
                    required
                    placeholder="أدخل اسمك"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="comment-content">التعليق</label>
                  <textarea
                    id="comment-content"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    required
                    placeholder="أكتب تعليقك هنا"
                    rows="6"
                  ></textarea>
                </div>
                <button 
                  type="submit" 
                  className="submit-comment" 
                  disabled={commentSubmitting}
                >
                  {commentSubmitting ? 'جاري الإرسال...' : 'إرسال التعليق'}
                </button>
                <p className="comment-notice">
                  * التعليقات تخضع للمراجعة قبل الظهور
                </p>
              </form>
            </div>
          )}
          
          {relatedPosts.length > 0 && (
            <div className="related-posts">
              <h3 className="related-title">منشورات ذات صلة</h3>
              <div className="related-posts-grid">
                {relatedPosts.map(relatedPost => (
                  <div key={relatedPost.id} className="related-post-card">
                    <Link to={`/post/${relatedPost.id}`}>
                      <div className="related-post-image">
                        <img 
                          src={relatedPost.image && relatedPost.image.trim() !== '' ? relatedPost.image : (relatedPost.imageUrl && relatedPost.imageUrl.trim() !== '' ? relatedPost.imageUrl : '/logo.png')}
                          alt={relatedPost.title} 
                          onError={(e) => {
                            console.warn('Failed to load related post image:', e.target.src);
                            e.target.src = '/logo.png';
                            e.target.classList.add('fallback-image');
                          }}
                        />
                      </div>
                      <h4 className="related-post-title">{relatedPost.title}</h4>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostPage;
