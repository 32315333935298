import React from 'react';
import PostCard from './PostCard';
import '../styles/CategorySection.css';
import { useTheme } from '../contexts/ThemeContext';

const CategorySection = ({ title, posts, link }) => {
  const { darkMode } = useTheme();
  
  // معالجة مشكلة الصحة بشكل خاص
  let categoryLink = link;
  
  // إذا لم يتم تمرير رابط مخصص، استخدم العنوان (مع معالجة خاصة للصحة)
  if (!categoryLink) {
    if (title === 'الصحة') {
      categoryLink = '/category/health';
    } else {
      categoryLink = `/category/${encodeURIComponent(title)}`;
    }
  }
  
  return (
    <section className={`category-section ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="category-header">
        <h2 className="category-title">
          {title}
        </h2>
      </div>
      <div className="category-posts">
        {posts.slice(0, 4).map(post => (
          <PostCard key={post.id} post={post} />
        ))}
      </div>
    </section>
  );
};

export default CategorySection;
