import React, { useState, useEffect } from 'react';
import '../styles/HomePage.css';
import Banner from '../components/Banner';
import CategorySection from '../components/CategorySection';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const { darkMode } = useTheme();
  const [categories, setCategories] = useState([]);
  
  // استرجاع الأقسام والمنشورات من localStorage عند تحميل الصفحة
  useEffect(() => {
    // استرجاع الأقسام
    const savedCategories = localStorage.getItem('categories');
    const savedPosts = localStorage.getItem('posts');
    
    if (savedCategories) {
      try {
        const parsedCategories = JSON.parse(savedCategories);
        // تحويل الأقسام إلى التنسيق المطلوب لعرضها في الصفحة الرئيسية
        const formattedCategories = parsedCategories
          .filter(category => category.visible !== false) // عرض الأقسام المرئية فقط
          .map(category => {
            // البحث عن المنشورات المرتبطة بهذا القسم
            let categoryPosts = [];
            
            if (savedPosts) {
              try {
                const parsedPosts = JSON.parse(savedPosts);
                // تصفية المنشورات حسب القسم (المنشورة فقط)
                categoryPosts = parsedPosts.filter(post => 
                  (post.category === category.id || post.categoryId === category.id || 
                   post.category === category.name) && 
                  (post.status === 'published' || post.status === undefined)
                );

                // ترتيب المنشورات:
                // 1. المنشورات المثبتة أولاً
                // 2. ثم باقي المنشورات من الأحدث إلى الأقدم
                categoryPosts.sort((a, b) => {
                  if (a.isPinned && !b.isPinned) return -1;
                  if (!a.isPinned && b.isPinned) return 1;
                  return new Date(b.date) - new Date(a.date);
                });
              } catch (error) {
                console.error('خطأ في استرجاع المنشورات من localStorage:', error);
              }
            }
            
            return {
              id: category.id,
              title: category.name,
              link: `/category/${encodeURIComponent(category.name)}`,
              posts: categoryPosts.length > 0 ? categoryPosts : [
                // منشورات افتراضية في حالة عدم وجود منشورات حقيقية
                {
                  id: `${category.id}-default-1`,
                  title: 'لا توجد منشورات بعد',
                  excerpt: 'لم يتم إضافة منشورات لهذا القسم حتى الآن...',
                  date: new Date().toLocaleDateString('ar-EG'),
                  imageUrl: 'https://via.placeholder.com/300x200',
                  category: category.id
                }
              ]
            };
          });
        
        setCategories(formattedCategories);
      } catch (error) {
        console.error('خطأ في استرجاع الأقسام من localStorage:', error);
        // في حالة الخطأ، استخدم البيانات الافتراضية
        setCategories(getDefaultCategories());
      }
    } else {
      // إذا لم تكن هناك أقسام محفوظة، استخدم البيانات الافتراضية
      setCategories(getDefaultCategories());
    }
  }, []);
  
  // إضافة مستمع للتغييرات في localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedCategories = localStorage.getItem('categories');
      const savedPosts = localStorage.getItem('posts');
      
      if (savedCategories) {
        try {
          const parsedCategories = JSON.parse(savedCategories);
          // تحديث الأقسام بنفس المنطق السابق
          const formattedCategories = parsedCategories
            .filter(category => category.visible !== false) // عرض الأقسام المرئية فقط
            .map(category => {
              let categoryPosts = [];
              
              if (savedPosts) {
                try {
                  const parsedPosts = JSON.parse(savedPosts);
                  // تصفية المنشورات حسب القسم والحالة
                  categoryPosts = parsedPosts.filter(post => 
                    (post.category === category.id || post.categoryId === category.id || 
                     post.category === category.name) && 
                    (post.status === 'published' || post.status === undefined)
                  );

                  // ترتيب المنشورات:
                  // 1. المنشورات المميزة أولاً
                  // 2. ثم المنشورات المثبتة
                  // 3. ثم باقي المنشورات من الأحدث إلى الأقدم
                  categoryPosts.sort((a, b) => {
                    if (a.isFeatured && !b.isFeatured) return -1;
                    if (!a.isFeatured && b.isFeatured) return 1;
                    if (a.isPinned && !b.isPinned) return -1;
                    if (!a.isPinned && b.isPinned) return 1;
                    return new Date(b.date) - new Date(a.date);
                  });
                } catch (error) {
                  console.error('خطأ في استرجاع المنشورات المحدثة:', error);
                }
              }
              
              return {
                id: category.id,
                title: category.name,
                link: `/category/${encodeURIComponent(category.name)}`,
                posts: categoryPosts.length > 0 ? categoryPosts : [
                  {
                    id: `${category.id}-default-1`,
                    title: 'لا توجد منشورات بعد',
                    excerpt: 'لم يتم إضافة منشورات لهذا القسم حتى الآن...',
                    date: new Date().toLocaleDateString('ar-EG'),
                    imageUrl: 'https://via.placeholder.com/300x200',
                    category: category.id
                  }
                ]
              };
            });
          
          setCategories(formattedCategories);
        } catch (error) {
          console.error('خطأ في استرجاع الأقسام المحدثة:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    // تنفيذ وظيفة التحديث عند تحميل الصفحة وعند التغييرات
    handleStorageChange();
    
    // أيضًا نضيف مستمع مخصص للتحديثات من AdminPage
    const checkForChanges = setInterval(() => {
      handleStorageChange();
    }, 1000); // التحقق كل ثانية لضمان التحديث السريع

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(checkForChanges);
    };
  }, []);
  
  // بيانات الأقسام الافتراضية في حالة عدم وجود أقسام محفوظة
  const getDefaultCategories = () => [
    {
      id: 2,
      title: 'التكنولوجيا',
      link: '/category/technology',
      posts: [
        {
          id: 201,
          title: 'مستقبل الذكاء الاصطناعي في حياتنا اليومية',
          excerpt: 'كيف سيغير الذكاء الاصطناعي طريقة تعاملنا مع التكنولوجيا في السنوات القادمة...',
          date: '5 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'التكنولوجيا'
        },
        {
          id: 202,
          title: 'تقنيات الواقع المعزز وتطبيقاتها في التعليم',
          excerpt: 'استخدامات مبتكرة للواقع المعزز في المؤسسات التعليمية وكيفية الاستفادة منها...',
          date: '4 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'التكنولوجيا'
        },
        {
          id: 203,
          title: 'أمن المعلومات: كيفية حماية بياناتك الشخصية',
          excerpt: 'نصائح وإرشادات عملية لحماية خصوصيتك على الإنترنت في عصر القرصنة الإلكترونية...',
          date: '3 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'التكنولوجيا'
        },
        {
          id: 204,
          title: 'تطبيقات الهاتف المحمول التي ستغير حياتك',
          excerpt: 'قائمة بأهم التطبيقات التي يجب أن تكون موجودة في هاتفك الذكي لتسهيل مهامك اليومية...',
          date: '2 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'التكنولوجيا'
        }
      ]
    },
    {
      id: 3,
      title: 'الثقافة',
      link: '/category/culture',
      posts: [
        {
          id: 301,
          title: 'الأدب العربي المعاصر: اتجاهات وتحديات',
          excerpt: 'نظرة تحليلية على واقع الأدب العربي المعاصر والتحديات التي تواجهه في ظل التحولات الراهنة...',
          date: '5 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الثقافة'
        },
        {
          id: 302,
          title: 'كيف تقرأ كتاباً في يوم واحد؟',
          excerpt: 'تقنيات القراءة السريعة واستراتيجيات فعالة لزيادة معدل القراءة والاستيعاب...',
          date: '4 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الثقافة'
        },
        {
          id: 303,
          title: 'التراث الثقافي العربي: كيف نحافظ عليه؟',
          excerpt: 'أهمية الحفاظ على الهوية الثقافية العربية في ظل العولمة وتأثيراتها المختلفة...',
          date: '3 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الثقافة'
        },
        {
          id: 304,
          title: 'فن الخط العربي: بين الأصالة والمعاصرة',
          excerpt: 'رحلة في عالم الخط العربي وتطوره عبر العصور وكيفية توظيفه في الفنون المعاصرة...',
          date: '2 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الثقافة'
        }
      ]
    },
    {
      id: 4,
      title: 'الاقتصاد',
      link: '/category/economy',
      posts: [
        {
          id: 401,
          title: 'الاستثمار المبكر: كيف تبدأ رحلتك المالية؟',
          excerpt: 'دليل للمبتدئين حول كيفية بدء رحلة الاستثمار وبناء مستقبل مالي مستقر...',
          date: '5 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الاقتصاد'
        },
        {
          id: 402,
          title: 'العملات الرقمية: مستقبل التعاملات المالية',
          excerpt: 'نظرة شاملة على عالم العملات الرقمية وتأثيرها المتوقع على الاقتصاد العالمي...',
          date: '4 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الاقتصاد'
        },
        {
          id: 403,
          title: 'التضخم وتأثيره على المدخرات الشخصية',
          excerpt: 'كيف يؤثر التضخم على قيمة مدخراتك وما هي استراتيجيات الحماية منه...',
          date: '3 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الاقتصاد'
        },
        {
          id: 404,
          title: 'ريادة الأعمال: خطوات إنشاء مشروعك الناجح',
          excerpt: 'دليل عملي شامل لكل من يطمح إلى إنشاء مشروعه الخاص وتحقيق النجاح المالي...',
          date: '2 مارس 2025',
          imageUrl: 'https://via.placeholder.com/300x200',
          category: 'الاقتصاد'
        }
      ]
    }
  ];

  return (
    <div className="home-page">
      <div className="banner-wrapper">
        <Banner />
      </div>
      
      <div className="content-container">
        {/* عرض أقسام المدونة */}
        <div className="categories-container">
          {categories.map(category => (
            <CategorySection 
              key={category.id} 
              title={category.title} 
              posts={category.posts}
              link={category.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
