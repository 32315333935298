import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import '../styles/Header.css';

const Header = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isFullyScrolled, setIsFullyScrolled] = useState(false);
  const animationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // إضافة متغير حالة للأقسام
  const [categories, setCategories] = useState([]);
  
  // التحقق من حالة تسجيل الدخول عند تحميل الصفحة
  useEffect(() => {
    const checkLoginStatus = () => {
      const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
      setIsLoggedIn(loggedIn);
    };
    
    checkLoginStatus();
    
    // إضافة مستمع للتغييرات في localStorage
    const handleStorageChange = () => {
      checkLoginStatus();
    };
    
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  // تحديث حالة تسجيل الدخول عند تغيير المسار
  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, [location.pathname]);
  
  // قراءة الأقسام من localStorage عند تحميل الصفحة
  useEffect(() => {
    const fetchCategories = () => {
      const savedCategories = localStorage.getItem('categories');
      if (savedCategories) {
        try {
          const parsedCategories = JSON.parse(savedCategories);
          if (Array.isArray(parsedCategories)) {
            // تحويل نموذج البيانات ليناسب الهيدر
            const formattedCategories = parsedCategories
              .filter(cat => cat.visible)
              .map(cat => ({
                id: cat.id,
                title: cat.name,
                link: `/category/${encodeURIComponent(cat.name)}`
              }));
            setCategories(formattedCategories);
          }
        } catch (error) {
          console.error('خطأ في قراءة الأقسام من localStorage:', error);
        }
      }
    };

    // قراءة الأقسام عند تحميل المكون
    fetchCategories();

    // إضافة مستمع للتغييرات في localStorage
    const handleStorageChange = () => {
      fetchCategories();
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // التعامل مع حدث التمرير لتحديث شريط التقدم
  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      
      if (totalHeight) {
        const progress = (scrollPosition / totalHeight);
        setScrollProgress(progress);
        setIsFullyScrolled(progress >= 0.99);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // دالة لمعالجة النقر على زر المسؤول
  const handleAdminClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      // الانتقال مباشرة إلى صفحة تسجيل الدخول بدون رسالة تأكيد
      navigate('/admin');
      
      // ضمان تحويل التبويب إلى حسابات وعرض نموذج تسجيل الدخول عند الوصول
      localStorage.setItem('adminRedirectTab', 'accounts');
      localStorage.setItem('adminAccountSection', 'login');
    }
  };
  
  // دالة لمعالجة تسجيل الخروج
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentUser');
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <header className={`header ${isFullyScrolled ? 'scrolled' : ''}`} style={{ '--scroll-progress': scrollProgress }}>
      <div className="header-container">
        <div className="header-buttons">
          <Link to="/admin" className="admin-btn" onClick={handleAdminClick}>
            <i className="fas fa-user-shield"></i> مسؤول
          </Link>
          
          <button 
            className="theme-toggle" 
            onClick={toggleDarkMode}
            aria-label={darkMode ? 'تفعيل الوضع النهاري' : 'تفعيل الوضع الليلي'}
          >
            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
          </button>
          
          {isLoggedIn && (
            <button 
              className="logout-btn" 
              onClick={handleLogout}
              aria-label="تسجيل الخروج"
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          )}
          
          <nav className="categories-nav">
            <ul className="categories-list">
              {categories.map(category => (
                <li key={category.id} className="category-item">
                  <Link to={category.link} className="category-link">
                    {category.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        
        <div className="logo-container">
          <Link to="/">
            <img 
              src="/logo/logo.png" 
              alt="شعار El Gedid" 
              className="logo" 
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
