import React, { useState } from 'react';
import '../styles/Banner.css';
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Banner = () => {
  const { darkMode } = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [isActive, setIsActive] = useState(false);

  // تتبع تغيير قيمة البحث
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setIsActive(true);
    }
  };

  // تتبع حالة تمرير المؤشر
  const handleMouseEnter = () => {
    setIsActive(true);
  };

  // تتبع رفع المؤشر
  const handleMouseLeave = () => {
    if (!searchValue && !document.activeElement.classList.contains('search-input')) {
      setIsActive(false);
    }
  };

  // تتبع التركيز
  const handleFocus = () => {
    setIsActive(true);
  };

  // تتبع فقدان التركيز
  const handleBlur = () => {
    if (!searchValue) {
      setIsActive(false);
    }
  };

  return (
    <div className="banner">
      <div className="banner-overlay"></div>
      <div className="banner-content">
        <h1 className="banner-title">أهلا بك في مدونة El Gedid</h1>
        <h2 className="banner-subtitle">نكافح من أجل إحياء العقول</h2>
        <div 
          className={`search-container ${darkMode ? 'dark' : 'light'} ${isActive ? 'active' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input
            type="text"
            placeholder="بحث..."
            onChange={handleSearchChange}
            value={searchValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="search-input"
          />
          <button type="submit" className="search-button">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
