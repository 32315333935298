import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import CategoryPage from './pages/CategoryPage';
import PostPage from './pages/PostPage';
import { useTheme } from './contexts/ThemeContext';
import './styles/App.css';

function App() {
  const { darkMode } = useTheme();
  const location = useLocation();
  const isAdminPage = location.pathname === '/admin' || location.pathname === '/admin-comments';
  
  return (
    <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin-comments" element={<AdminPage initialTab="comments" />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
          <Route path="/post/:postId" element={<PostPage />} />
        </Routes>
      </main>
      {!isAdminPage && <Footer />}
    </div>
  );
}

export default App;
