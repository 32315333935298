import React, { createContext, useContext, useState, useEffect } from 'react';

// إنشاء سياق الثيم
const ThemeContext = createContext();

// دالة مساعدة لاستخدام سياق الثيم
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  // استرجاع وضع الثيم من التخزين المحلي إذا كان موجودًا
  const [darkMode, setDarkMode] = useState(() => {
    try {
      const savedMode = localStorage.getItem('darkMode');
      return savedMode === 'true';
    } catch (error) {
      console.error('خطأ في استرجاع حالة الثيم:', error);
      return false;
    }
  });

  // تحديث التخزين المحلي عند تغيير الثيم
  useEffect(() => {
    try {
      localStorage.setItem('darkMode', darkMode.toString());
      // إضافة class للجسم لضمان تطبيق الثيم بشكل عام على التطبيق
      if (darkMode) {
        document.body.classList.add('dark-mode');
        document.body.classList.remove('light-mode');
      } else {
        document.body.classList.add('light-mode');
        document.body.classList.remove('dark-mode');
      }
    } catch (error) {
      console.error('خطأ في حفظ حالة الثيم:', error);
    }
  }, [darkMode]);

  // تحقق من حالة الثيم عند تحميل أي صفحة أو التنقل بين الصفحات
  useEffect(() => {
    const checkTheme = () => {
      try {
        const savedMode = localStorage.getItem('darkMode') === 'true';
        if (savedMode !== darkMode) {
          setDarkMode(savedMode);
        }
      } catch (error) {
        console.error('خطأ في التحقق من حالة الثيم:', error);
      }
    };

    // التحقق عند تحميل الصفحة
    checkTheme();

    // إضافة مستمع للتغييرات في localStorage (مفيد في حالة تعدد التبويبات)
    const handleStorageChange = (e) => {
      if (e.key === 'darkMode') {
        checkTheme();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    // التحقق عند التنقل بين الصفحات
    const handleRouteChange = () => {
      checkTheme();
    };
    
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [darkMode]);

  // تبديل الوضع بين الوضع الليلي والنهاري
  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // توفير بيانات الثيم للمكونات الفرعية
  const value = {
    darkMode,
    toggleDarkMode
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};
