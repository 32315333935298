import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTheme } from "../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faHome, faUser, faList, faFile, faCog, faEye, faTrash, 
  faGripVertical, faEdit, faPlus, faTrashAlt, faChartLine, faCalendarDay, 
  faCalendarAlt, faCalendarWeek, faHistory, faComments, faSignOutAlt, faCopy, faSync,
  faEnvelope, faMousePointer, faUserPlus, faKey, faTimes, faCloudUploadAlt,
  faSave, faPaperPlane, faCalendarCheck, faSearch, faCheckSquare, faEllipsisV, faLink, faClock, 
  faAngleDoubleRight, faAngleRight, faAngleLeft, faAngleDoubleLeft, faEyeSlash, faChevronRight, faChevronLeft,
  faFolder, faSun, faMoon, faCode, faCheck, faBan, faExternalLinkAlt, faThumbtack, faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import "../styles/AdminPage.css";
import "../styles/AccountsPages.css";
import "../styles/loading.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { saveImage } from "../utils/imageHandler";
import '../styles/starfall.css';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import axios from 'axios';
const AdminPage = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('createPost');
  const [subTabView, setSubTabView] = useState('posts');
  const [editingCategory, setEditingCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [newCategory, setNewCategory] = useState({ name: '' });
  const [editingPostId, setEditingPostId] = useState(null);

  // متغيرات حالة لدعم التحكم في الشريط الجانبي والوضع الداكن
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const contentRef = useRef(null);
  
  // حالة الأقسام
  const [categories, setCategories] = useState([]);
  
  // حالة المنشور الجديد
  const [newPostTitle, setNewPostTitle] = useState('');
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostCategory, setNewPostCategory] = useState('');
  const [newPostImage, setNewPostImage] = useState(null);
  const [newPostTags, setNewPostTags] = useState([]);
  const [newPostExcerpt, setNewPostExcerpt] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');
  const [allowComments, setAllowComments] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editorFocused, setEditorFocused] = useState(false);

  // حالة المنشورات
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]); // متغير جديد
  
  // حالة البحث والفلترة في صفحة إدارة المنشورات
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('date-desc');
  const [isSearchActive, setIsSearchActive] = useState(false);

  // حالة التعليقات
  const [comments, setComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [commentSearchQuery, setCommentSearchQuery] = useState('');
  const [isCommentSearchActive, setIsCommentSearchActive] = useState(false);
  const [commentFilterStatus, setCommentFilterStatus] = useState('all');
  const [currentCommentPage, setCurrentCommentPage] = useState(1);
  const [commentsPerPage] = useState(10);

  // حالة الحسابات المستخدمين
  const [users, setUsers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [accountSection, setAccountSection] = useState('login'); 
  
  // حالة إضافة المستخدم
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [accountType, setAccountType] = useState('admin');
  const [selectedPermissions, setSelectedPermissions] = useState({
    createCategory: true,
    deleteCategory: true,
    createPost: true,
    deletePost: true
  });

  // إحصائيات الزيارات (بيانات وهمية للمثال)
  const [visitStats, setVisitStats] = useState({
    day: [
      { label: '1 ص', value: 24 },
      { label: '4 ص', value: 13 },
      { label: '7 ص', value: 31 },
      { label: '10 ص', value: 62 },
      { label: '1 م', value: 78 },
      { label: '4 م', value: 95 },
      { label: '7 م', value: 84 },
      { label: '10 م', value: 40 }
    ],
    month: [
      { label: '1', value: 245 },
      { label: '5', value: 378 },
      { label: '10', value: 289 },
      { label: '15', value: 410 },
      { label: '20', value: 462 },
      { label: '25', value: 385 },
      { label: '30', value: 502 }
    ],
    year: [
      { label: 'يناير', value: 3450 },
      { label: 'فبراير', value: 4210 },
      { label: 'مارس', value: 3890 },
      { label: 'إبريل', value: 5280 },
      { label: 'مايو', value: 4805 },
      { label: 'يونيو', value: 5920 },
      { label: 'يوليو', value: 6305 },
      { label: 'أغسطس', value: 5890 },
      { label: 'سبتمبر', value: 6410 },
      { label: 'أكتوبر', value: 7320 },
      { label: 'نوفمبر', value: 6985 },
      { label: 'ديسمبر', value: 8210 }
    ],
    all: {
      totalVisits: 128650,
      avgDaily: 457,
      avgMonthly: 13525,
      highestDay: {
        date: '15 أكتوبر 2024',
        count: 1243
      }
    }
  });

  // حالة المعاينة
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [previewPost, setPreviewPost] = useState(null);

  // إضافة متغيرات حالة جديدة
  const [activeAnalyticsTab, setActiveAnalyticsTab] = useState('day');

  // إضافة متغير حالة لتتبع وضع تحرير التبويبات
  const [editingTabs, setEditingTabs] = useState(false);

  // تعريف الأزرار/التبويبات في لوحة التحكم
  const [adminTabs, setAdminTabs] = useState([
    { id: 'createPost', title: 'إنشاء منشور', icon: faPlus, order: 0, visible: true },
    { id: 'postsAndCategories', title: 'أقسام ومنشورات', icon: faFolder, order: 1, visible: true },
    { id: 'comments', title: 'إدارة التعليقات', icon: faComments, order: 2, visible: true },
    { id: 'newsletter', title: 'النشرة البريدية', icon: faEnvelope, order: 3, visible: true },
    { id: 'accounts', title: '', icon: faUser, order: 4, visible: true },
    { id: 'analytics', title: 'الإحصائيات', icon: faChartLine, order: 5, visible: true },
    { id: 'api', title: 'API', icon: faCode, order: 6, visible: true }
  ]);

  // وظيفة لتحريك عنصر من مكان إلى مكان آخر في المصفوفة
  const moveItem = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [removed] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, removed);
    return newArray;
  };

  // تحكم بالشريط الجانبي
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  // حفظ إعدادات الوضع الداكن
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode ? 'true' : 'false');
    
    // إضافة أو إزالة فئة الوضع الداكن من عنصر الجسم
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  // التكيف مع حجم الشاشة
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      
      // تغيير حالة الشريط الجانبي حسب حجم الشاشة
      if (mobile) {
        setSidebarCollapsed(true);
      }
    };
    
    handleResize(); // تنفيذ أول مرة
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // تأثير الانتقال بين التبويبات
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.classList.add('fade-in');
      const timer = setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.classList.remove('fade-in');
        }
      }, 300);
      
      return () => clearTimeout(timer);
    }
  }, [activeTab]);

  // وظيفة لبدء سحب العنصر
  const startDrag = (e, type, index) => {
    e.dataTransfer.setData('type', type);
    e.dataTransfer.setData('index', index);
    e.target.classList.add('dragging');
  };

  // وظيفة لمنع السلوك الافتراضي عند السحب فوق عنصر
  const dragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('drag-over');
  };

  // وظيفة لإزالة تأثير السحب عند مغادرة العنصر
  const dragLeave = (e) => {
    e.currentTarget.classList.remove('drag-over');
  };

  // وظيفة لإفلات العنصر وإعادة ترتيب العناصر
  const drop = (e, type, toIndex) => {
    e.preventDefault();
    const sourceType = e.dataTransfer.getData('type');
    const sourceIndex = parseInt(e.dataTransfer.getData('index'));
    
    e.currentTarget.classList.remove('drag-over');
    
    // تأكد من أن نوع العنصر المسحوب هو نفس نوع العنصر المستهدف
    if (type !== sourceType) return;
    
    console.log(`Moved ${type} from ${sourceIndex} to ${toIndex}`);
    
    if (type === 'sidebar') {
      // إعادة ترتيب أزرار الشريط الجانبي
      const visibleTabs = adminTabs.filter(tab => tab.visible);
      const newVisibleTabs = moveItem(visibleTabs, sourceIndex, toIndex);
      
      // تحديث كل الأزرار مع الحفاظ على العناصر المخفية
      const updatedTabs = adminTabs.map(tab => {
        if (!tab.visible) return tab;
        
        const visibleIndex = newVisibleTabs.findIndex(vt => vt.id === tab.id);
        return {
          ...tab,
          order: visibleIndex
        };
      });
      
      // ترتيب الأزرار حسب الترتيب الجديد
      updatedTabs.sort((a, b) => {
        if (!a.visible && !b.visible) return 0;
        if (!a.visible) return 1;
        if (!b.visible) return -1;
        return a.order - b.order;
      });
      
      setAdminTabs(updatedTabs);
      localStorage.setItem('adminTabs', JSON.stringify(updatedTabs));
      
    } else if (type === 'settings') {
      // إعادة ترتيب أزرار الإعدادات
      const newTabs = moveItem(adminTabs, sourceIndex, toIndex);
      const updatedTabs = newTabs.map((tab, index) => ({
        ...tab,
        order: index
      }));
      
      setAdminTabs(updatedTabs);
      localStorage.setItem('adminTabs', JSON.stringify(updatedTabs));
      
    } else if (type === 'category') {
      // إعادة ترتيب الأقسام
      const newCategories = moveItem(categories, sourceIndex, toIndex);
      const updatedCategories = newCategories.map((cat, index) => ({
        ...cat,
        order: index + 1
      }));
      
      setCategories(updatedCategories);
      localStorage.setItem('categories', JSON.stringify(updatedCategories));
    }
  };

  // وظيفة لإنهاء السحب وإزالة تأثير السحب
  const endDrag = (e) => {
    e.target.classList.remove('dragging');
  };

  // حفظ الأزرار المحفوظة عند تحميل الصفحة
  useEffect(() => {
    const savedTabs = localStorage.getItem('adminTabs');
    if (savedTabs) {
      try {
        const parsedTabs = JSON.parse(savedTabs);
        // تحقق مما إذا كانت البيانات المجزأة صالحة
        if (Array.isArray(parsedTabs) && parsedTabs.length > 0) {
          setAdminTabs(parsedTabs);
        }
      } catch (error) {
        console.error('خطأ في تحليل الأزرار المحفوظة:', error);
      }
    }

    const savedCategories = localStorage.getItem('categories');
    if (savedCategories) {
      try {
        const parsedCategories = JSON.parse(savedCategories);
        if (Array.isArray(parsedCategories) && parsedCategories.length > 0) {
          setCategories(parsedCategories);
        }
      } catch (error) {
        console.error('خطأ في تحليل الفئات المحفوظة:', error);
      }
    }

    const savedPosts = localStorage.getItem('posts');
    if (savedPosts) {
      try {
        const parsedPosts = JSON.parse(savedPosts);
        if (Array.isArray(parsedPosts) && parsedPosts.length > 0) {
          setPosts(parsedPosts);
          setFilteredPosts(parsedPosts); // تهيئة المنشورات المفلترة
          applyCommentFilters(); // تطبيق فلترة التعليقات
        }
      } catch (error) {
        console.error('خطأ في تحليل المنشورات المحفوظة:', error);
      }
    }
  }, []);

  // حفظ ترتيب الأزرار في التخزين المحلي عند تغييرها
  useEffect(() => {
    try {
      localStorage.setItem('adminTabs', JSON.stringify(adminTabs));
    } catch (error) {
      console.error('خطأ في حفظ الأزرار في التخزين المحلي:', error);
    }
  }, [adminTabs]);

  // حفظ ترتيب الفئات في التخزين المحلي عند تغييرها
  useEffect(() => {
    try {
      localStorage.setItem('categories', JSON.stringify(categories));
    } catch (error) {
      console.error('خطأ في حفظ الفئات في التخزين المحلي:', error);
    }
  }, [categories]);

  // حفظ المنشورات في التخزين المحلي عند تغييرها
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // جلب المنشورات من API
        const postsData = await api.getAllPosts();
        setPosts(postsData);
        
        // جلب الفئات من API
        const categoriesData = await api.getAllCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error('خطأ في تحميل البيانات:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);
  // تصفير عداد الزيارات
  const handleResetVisits = () => {
    if (window.confirm('هل أنت متأكد من رغبتك في تصفير عداد الزيارات؟ هذا الإجراء لا يمكن التراجع عنه.')) {
      // هنا يمكن إضافة الكود الخاص بتصفير الزيارات في قاعدة البيانات
      // وللمثال سنقوم فقط بتصفير البيانات المحلية
      setVisitStats({
        day: visitStats.day.map(item => ({ ...item, value: 0 })),
        month: visitStats.month.map(item => ({ ...item, value: 0 })),
        year: visitStats.year.map(item => ({ ...item, value: 0 })),
        all: {
          totalVisits: 0,
          avgDaily: 0,
          avgMonthly: 0,
          highestDay: {
            date: new Date().toLocaleDateString('ar-EG'),
            count: 0
          }
        }
      });
      alert('تم تصفير عداد الزيارات بنجاح');
    }
  };

  // الحصول على أعلى قيمة في المخطط البياني
  const getMaxValue = (data) => {
    if (!Array.isArray(data)) {
      return 100; // قيمة افتراضية إذا لم تكن البيانات مصفوفة
    }
    return Math.max(...data.map(item => item.value)) * 1.1; // إضافة 10% للهامش
  };

  // رسم المخطط البياني
  const renderChart = (data) => {
    if (!Array.isArray(data)) {
      return <div className="chart-placeholder">لا توجد بيانات متاحة للعرض</div>;
    }
    
    const maxValue = getMaxValue(data);
    const gridLines = 5; // عدد خطوط الشبكة
    
    return (
      <div className="chart-container">
        {/* شبكة المخطط */}
        <div className="chart-grid">
          {Array.from({ length: gridLines + 1 }).map((_, i) => (
            <div key={i} className="chart-grid-line" style={{ bottom: `${(i * 100) / gridLines}%` }}>
              <span className="chart-grid-value">
                {Math.round((maxValue * i) / gridLines)}
              </span>
            </div>
          ))}
        </div>
        
        {/* أعمدة المخطط */}
        <div className="chart-bars">
          {data.map((item, index) => (
            <div key={index} className="chart-bar">
              <div 
                className="chart-bar-fill"
                style={{ height: `${(item.value / maxValue) * 100}%` }}
              />
              <span className="chart-value">{item.value}</span>
              <span className="chart-label">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // إضافة قسم جديد
  const handleAddCategory = () => {
    if (newCategory.name.trim() !== '') {
      const newCategoryItem = {
        id: Date.now().toString(),
        name: newCategory.name,
        order: categories.length + 1,
        visible: true
      };
      setCategories([...categories, newCategoryItem]);
      setNewCategory({ name: '' });
      
      // تحديث الأقسام في الهيدر عبر localStorage
      const updatedCategories = [...categories, newCategoryItem];
      localStorage.setItem('categories', JSON.stringify(updatedCategories));
    }
  };

  // تحديث قسم
  const handleUpdateCategory = (id) => {
    if (!editingCategory || editingCategory.name.trim() === '') return;
    
    const updatedCategories = categories.map(category => 
      category.id === id ? { ...category, name: editingCategory.name } : category
    );
    
    setCategories(updatedCategories);
    setEditingCategory(null);
  };

  // حذف قسم
  const handleDeleteCategory = (id) => {
    const updatedCategories = categories.filter(category => category.id !== id);
    setCategories(updatedCategories);
  };

  // تكوين محرر Quill
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'direction': 'rtl' }],
      ],
      handlers: {
        'image': function() {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          
          input.onchange = async () => {
            if (input.files && input.files[0]) {
              const file = input.files[0];
              try {
                setIsLoading(true);
                const imageUrl = await saveImage(file);
                
                // الحصول على مرجع للمحرر
                const editor = this.quill;
                
                // الحصول على موضع المؤشر الحالي
                const range = editor.getSelection(true);
                
                // إدراج الصورة في موضع المؤشر
                editor.insertEmbed(range.index, 'image', imageUrl);
                
                // تحريك المؤشر بعد الصورة
                editor.setSelection(range.index + 1);
                
                // تحديث المحتوى
                setNewPostContent(editor.root.innerHTML);
              } catch (error) {
                console.error('خطأ في رفع الصورة:', error);
                alert('حدث خطأ أثناء رفع الصورة. يرجى المحاولة مرة أخرى.');
              } finally {
                setIsLoading(false);
              }
            }
          };
        },
      }
    },
    clipboard: {
      matchVisual: false
    },
  }), []);
  
  // الأنساق المتاحة في المحرر
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'align', 'color', 'background', 'direction'
  ];
  
  // التعامل مع تغيير محتوى المحرر
  const handleEditorChange = (content) => {
    setNewPostContent(content);
  };
  
  // التعامل مع تركيز المحرر
  const handleEditorFocus = () => {
    setEditorFocused(true);
  };
  
  // التعامل مع فقدان تركيز المحرر
  const handleEditorBlur = () => {
    setEditorFocused(false);
  };

  const handlePostSubmit = async () => {
    if (!newPostTitle || !newPostContent) {
      alert('يرجى ملء العنوان والمحتوى على الأقل');
      return;
    }
    
    try {
      setIsLoading(true);
      
      // رفع الصورة إلى المخدم المحلي إذا كانت موجودة
      let imageUrl = null;
      if (newPostImage) {
        try {
          const formData = new FormData();
          formData.append('image', newPostImage);
          
          const response = await axios.post('/api/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          
          imageUrl = response.data.imageUrl;
        } catch (error) {
          console.error('خطأ في رفع الصورة:', error);
          alert('حدث خطأ أثناء رفع الصورة');
          setIsLoading(false);
          return;
        }
      }
      
      // إعداد بيانات المنشور
      const postData = {
        title: newPostTitle,
        content: newPostContent,
        excerpt: newPostExcerpt || newPostContent.substring(0, 150) + '...',
        category_id: newPostCategory || null,
        status: isScheduled ? 'scheduled' : 'published',
        scheduled_date: isScheduled ? scheduledDate : null,
        allow_comments: allowComments,
        is_featured: isFeatured,
        tags: newPostTags,
        featured_image: imageUrl
      };
      
      // إرسال المنشور إلى API
      const result = await api.createPost(postData);
      
      // إعادة تحميل المنشورات من السيرفر
      const updatedPosts = await api.getAllPosts();
      setPosts(updatedPosts);
      
      // إعادة تعيين النموذج
      resetPostForm();
      
      // إظهار رسالة نجاح
      alert('تم نشر المنشور بنجاح!');
      
      // تغيير التبويب إلى إدارة المنشورات
      setActiveTab('postsAndCategories');
    } catch (error) {
      console.error('حدث خطأ أثناء نشر المنشور:', error);
      alert('حدث خطأ أثناء نشر المنشور. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // إعادة تعيين نموذج المنشور
  const resetPostForm = () => {
    setNewPostTitle('');
    setNewPostContent('');
    setNewPostCategory('');
    setNewPostImage(null);
    setNewPostTags([]);
    setNewPostExcerpt('');
    setIsScheduled(false);
    setScheduledDate('');
    setAllowComments(false);
    setIsFeatured(false);
  };

  // معاينة المنشور
  const handlePostPreview = async () => {
    // إذا كان عنوان المنشور أو المحتوى فارغين
    if (!newPostTitle.trim() || !newPostContent.trim()) {
      alert('يرجى إدخال عنوان ومحتوى للمنشور قبل المعاينة');
      return;
    }
    
    try {
      // إظهار حالة التحميل
      setIsLoading(true);
      
      // رفع الصورة إلى المخدم المحلي للمعاينة أيضًا، بدلاً من استخدام URL.createObjectURL
      let newPostImage = null;
      if (newPostImage) {
        // نستخدم نفس وظيفة رفع الصور المستخدمة عند النشر
        try {
          newPostImage = await saveImage(newPostImage);
        } catch (error) {
          console.error('خطأ في رفع الصورة للمعاينة:', error);
          alert('حدث خطأ أثناء حفظ الصورة للمعاينة. يرجى المحاولة مرة أخرى.');
          setIsLoading(false);
          return;
        }
      }
      
      // إعداد كائن المنشور للمعاينة
      const postToPreview = {
        id: Date.now().toString(),
        title: newPostTitle,
        content: newPostContent,
        category: newPostCategory,
        image: newPostImage,
        tags: newPostTags || [],
        excerpt: newPostExcerpt,
        scheduled: isScheduled,
        scheduledDate: isScheduled ? scheduledDate : new Date().toISOString(),
        allowComments: allowComments,
        isFeatured: isFeatured,
        author: 'المستخدم الحالي',
        date: new Date().toISOString(),
        status: 'draft'
      };
      
      // تحديث حالة المعاينة
      setPreviewPost(postToPreview);
      setIsPreviewMode(true);
    } catch (error) {
      console.error('حدث خطأ أثناء تحضير المعاينة:', error);
      alert('حدث خطأ أثناء تحضير المعاينة. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };

  // إغلاق المعاينة
  const closePreview = () => {
    setIsPreviewMode(false);
    setPreviewPost(null);
  };

  // حذف منشور
  const handleDeletePost = async (postId) => {
    if (!window.confirm('هل أنت متأكد من رغبتك في حذف هذا المنشور؟')) {
      return;
    }
    
    try {
      setIsLoading(true);
      
      // حذف المنشور عبر API
      await api.deletePost(postId);
      
      // إعادة تحميل المنشورات من السيرفر
      const updatedPosts = await api.getAllPosts();
      setPosts(updatedPosts);
      
      // عرض رسالة نجاح
      alert('تم حذف المنشور بنجاح');
    } catch (error) {
      console.error('خطأ في حذف المنشور:', error);
      alert('حدث خطأ أثناء حذف المنشور');
    } finally {
      setIsLoading(false);
    }
  };
  // تعديل منشور
  const handleEditPost = async (postId) => {
    try {
      setIsLoading(true);
      
      // جلب بيانات المنشور من API
      const postData = await api.getPost(postId);
      
      if (postData) {
        // تحديث حالة النموذج بمعلومات المنشور
        setNewPostTitle(postData.title);
        setNewPostContent(postData.content);
        setNewPostCategory(postData.category_id);
        setNewPostImage(postData.featured_image);
        setNewPostExcerpt(postData.excerpt || '');
        setNewPostTags(postData.tags || []);
        setIsScheduled(postData.status === 'scheduled');
        setScheduledDate(postData.scheduled_date || '');
        setAllowComments(postData.allow_comments || false);
        setIsFeatured(postData.is_featured || false);
        
        // تخزين معرف المنشور الذي يتم تحريره
        setEditingPostId(postId);
        
        // التبديل إلى تبويب إنشاء منشور
        setActiveTab('createPost');
      }
    } catch (error) {
      console.error('خطأ في جلب بيانات المنشور للتعديل:', error);
      alert('حدث خطأ أثناء تحميل بيانات المنشور للتعديل');
    } finally {
      setIsLoading(false);
    }
  };

  // مشاركة رابط المنشور
  const handleSharePostLink = (postId) => {
    const postLink = `${window.location.origin}/post/${postId}`;
    
    // نسخ الرابط إلى الحافظة
    navigator.clipboard.writeText(postLink)
      .then(() => {
        alert('تم نسخ رابط المنشور بنجاح!');
      })
      .catch(err => {
        console.error('فشل في نسخ الرابط: ', err);
      });
  };

  // عرض المنشور
  const handleViewPost = (postId) => {
    window.open(`/post/${postId}`, '_blank');
  };

  // حساب عدد المنشورات في كل قسم
  const getPostsCountByCategory = (categoryId) => {
    return posts.filter(post => post.categoryId === categoryId).length;
  };

  // وظائف إدارة الأقسام
  const handleEditCategoryClick = (category) => {
    setEditingCategory({ ...category });
  };

  const handleSaveCategory = () => {
    if (!editingCategory || editingCategory.name.trim() === '') return;

    const updatedCategories = categories.map(cat => 
      cat.id === editingCategory.id ? { ...cat, name: editingCategory.name } : cat
    );
    
    setCategories(updatedCategories);
    setEditingCategory(null);
  };

  // وظائف إدارة التنقل بين الصفحات
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // وظائف معاينة المنشورات
  const handlePreviewPost = (post) => {
    setPreviewPost(post);
    setIsPreviewMode(true);
  };

  // حسابات التنقل بين الصفحات
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
  
  // إنشاء أزرار التنقل بين الصفحات
  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(i);
  }

  // عرض محتوى التبويب النشط
  const renderTabContent = () => {
    // إذا كانت المعاينة نشطة، عرض المنشور بشكل المعاينة
    if (isPreviewMode && previewPost) {
      return (
        <div className="admin-panel-section">
          <div className="preview-header">
            <h2>معاينة المنشور</h2>
            <div className="preview-actions">
              <button className="back-btn" onClick={() => {
                closePreview();
                setActiveTab('postsAndCategories');
              }}>
                <FontAwesomeIcon icon={faArrowLeft} /> العودة لقسم منشورات وأقسام
              </button>
            </div>
          </div>
          
          <div className="post-preview-container">
            <div className="post-preview">
              <h1 className="preview-post-title">{previewPost.title}</h1>
              
              <div className="preview-post-meta">
                <div className="preview-post-author">
                  <FontAwesomeIcon icon={faUser} /> {previewPost.author}
                </div>
                <div className="preview-post-date">
                  <FontAwesomeIcon icon={faCalendarAlt} /> {new Date(previewPost.date).toLocaleDateString('ar-SA')}
                </div>
                <div className="preview-post-category">
                  <FontAwesomeIcon icon={faFolder} /> 
                  {categories.find(cat => cat.id === previewPost.category)?.name || 'بدون قسم'}
                </div>
              </div>
              
              {previewPost.image && (
                <div className="preview-post-image">
                  <img src={previewPost.image} alt={previewPost.title} />
                </div>
              )}
              
              <div 
                className="preview-post-content"
                dangerouslySetInnerHTML={{ __html: previewPost.content }}
              />
              
              {previewPost.tags && previewPost.tags.length > 0 && (
                <div className="preview-post-tags">
                  <h3>الوسوم:</h3>
                  <div className="tags-list">
                    {previewPost.tags.map((tag, index) => (
                      <span key={index} className="tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    
    // إذا لم يكن المستخدم مسجلاً وليس في صفحة الحسابات، يجب توجيهه إلى صفحة تسجيل الدخول
    if (!isLoggedIn && activeTab !== 'accounts') {
      setActiveTab('accounts');
      return renderAccountsContent();
    }
    
    switch (activeTab) {
      case 'createPost':
        return (
          <div className="admin-panel-section">
            <h2>إنشاء منشور جديد</h2>
            
            <div className="modern-create-post">
              <div className="post-creation-grid">
                <div className="post-main-content">
                  <div className="post-form-group">
                    <label>عنوان المنشور</label>
                    <input 
                      type="text" 
                      className="modern-input" 
                      placeholder="أدخل عنوانًا مميزًا لمنشورك..."
                      value={newPostTitle}
                      onChange={(e) => setNewPostTitle(e.target.value)}
                    />
                  </div>
                  
                  <div className="post-form-group">
                    <label>محتوى المنشور</label>
                    <div className="editor-container">
                      <ReactQuill
                        theme="snow"
                        value={newPostContent}
                        onChange={handleEditorChange}
                        onFocus={handleEditorFocus}
                        onBlur={handleEditorBlur}
                        placeholder="ابدأ بكتابة محتوى المنشور هنا..."
                        modules={modules}
                        formats={formats}
                        preserveWhitespace={true}
                        style={{ minHeight: '300px', direction: 'rtl' }}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="post-settings-sidebar">
                  <div className="settings-card">
                    <h3>إعدادات النشر</h3>
                    
                    <div className="post-form-group">
                      <label>القسم</label>
                      <select 
                        className="modern-select" 
                        value={newPostCategory} 
                        onChange={(e) => setNewPostCategory(e.target.value)}
                      >
                        <option value="">اختر القسم</option>
                        {categories && categories.length > 0 ? (
                          categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))
                        ) : null}
                      </select>
                    </div>
                    
                    <div className="post-form-group">
                      <label>الصورة البارزة</label>
                      <div className="featured-image-uploader">
                        {newPostImage ? (
                          <div className="image-preview-container">
                            <img 
                              src={URL.createObjectURL(newPostImage)} 
                              alt="معاينة الصورة" 
                              className="image-preview" 
                            />
                            <button 
                              className="remove-image-btn"
                              onClick={() => setNewPostImage(null)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        ) : (
                          <div className="upload-box">
                            <input
                              type="file"
                              id="featured-image"
                              className="file-input"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                  setNewPostImage(e.target.files[0]);
                                }
                              }}
                            />
                            <label htmlFor="featured-image" className="upload-label">
                              <FontAwesomeIcon icon={faCloudUploadAlt} />
                              <span>اسحب الصورة هنا أو انقر للتحميل</span>
                            </label>
                          </div>
                        )}
                      </div>
                      
                      {isLoading ? (
                        <div className="loading-overlay">
                          <div className="loading-spinner"></div>
                          <p>جاري تحميل الصورة...</p>
                        </div>
                      ) : null}
                    </div>
                    
                    <div className="post-form-group">
                      <label>الكلمات المفتاحية</label>
                      <div className="tags-input-container">
                        <input
                          type="text"
                          className="tag-input"
                          placeholder="أضف كلمة ثم اضغط Enter..."
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                              e.preventDefault();
                              if (!newPostTags.includes(e.target.value.trim())) {
                                setNewPostTags([...newPostTags, e.target.value.trim()]);
                              }
                              e.target.value = '';
                            }
                          }}
                        />
                        <div className="tags-list">
                          {newPostTags && newPostTags.length > 0 ? (
                            newPostTags.map((tag, index) => (
                              <span key={index} className="tag">
                                {tag}
                                <button
                                  className="remove-tag"
                                  onClick={() => {
                                    setNewPostTags(newPostTags.filter((_, i) => i !== index));
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </span>
                            ))
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="settings-card">
                    <h3>خيارات إضافية</h3>
                    
                    <div className="post-form-group">
                      <label>وصف قصير للمنشور (النبذة)</label>
                      <textarea
                        className="modern-textarea"
                        placeholder="أدخل وصفًا قصيرًا يظهر في نتائج البحث ووسائل التواصل الاجتماعي..."
                        rows="4"
                        value={newPostExcerpt}
                        onChange={(e) => setNewPostExcerpt(e.target.value)}
                      ></textarea>
                      <div className="input-hint">الحد الأقصى: 160 حرف</div>
                    </div>
                    
                    <div className="post-form-group">
                      <label>جدولة النشر</label>
                      <div className="schedule-options">
                        <div className="radio-option">
                          <input
                            type="radio"
                            id="publish-now"
                            name="publish-time"
                            checked={!isScheduled}
                            onChange={() => setIsScheduled(false)}
                          />
                          <label htmlFor="publish-now">نشر الآن</label>
                        </div>
                        <div className="radio-option">
                          <input
                            type="radio"
                            id="publish-later"
                            name="publish-time"
                            checked={isScheduled}
                            onChange={() => setIsScheduled(true)}
                          />
                          <label htmlFor="publish-later">جدولة للنشر لاحقًا</label>
                        </div>
                      </div>
                      
                      {isScheduled && (
                        <div className="schedule-datetime">
                          <input
                            type="datetime-local"
                            className="modern-input"
                            value={scheduledDate}
                            onChange={(e) => setScheduledDate(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    
                    <div className="post-form-group">
                      <div className="toggle-option">
                        <label htmlFor="allow-comments">عدم السماح بالتعليقات</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="allow-comments"
                            checked={allowComments}
                            onChange={() => setAllowComments(!allowComments)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    
                    <div className="post-form-group">
                      <div className="toggle-option">
                        <label htmlFor="featured-post">تثبيت المنشور</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            id="featured-post"
                            checked={isFeatured}
                            onChange={() => setIsFeatured(!isFeatured)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="publish-actions">
                    <button className="save-draft-btn">
                      <FontAwesomeIcon icon={faSave} /> حفظ كمسودة
                    </button>
                    <button className="preview-btn" onClick={handlePostPreview}>
                      <FontAwesomeIcon icon={faEye} /> معاينة
                    </button>
                    <button className="publish-btn" onClick={handlePostSubmit}>
                      <FontAwesomeIcon icon={isScheduled ? faCalendarCheck : faPaperPlane} /> 
                      {isScheduled ? 'جدولة المنشور' : 'نشر المنشور'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        
      case 'postsAndCategories':
        return (
          <div className="admin-panel-section">
            <h2>إدارة المنشورات والأقسام</h2>
            
            <div className="tabs-container">
              <div className="tabs-header">
                <button 
                  className={`tab-button ${subTabView === 'posts' ? 'active' : ''}`}
                  onClick={() => setSubTabView('posts')}
                >
                  المنشورات
                </button>
                <button 
                  className={`tab-button ${subTabView === 'categories' ? 'active' : ''}`}
                  onClick={() => setSubTabView('categories')}
                >
                  الأقسام
                </button>
              </div>
              
              <div className="tabs-content">
                {subTabView === 'posts' && (
                  <div className="posts-management-panel">
                    <div className="posts-control-bar">
                      <div className="posts-actions">
                        <button className="add-button" onClick={() => setActiveTab('createPost')}>
                          <FontAwesomeIcon icon={faPlus} /> إنشاء منشور جديد
                        </button>
                      </div>
                      <div className="search-bar">
                        <input
                          type="text"
                          className="search-input"
                          placeholder="بحث في المنشورات..."
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setIsSearchActive(true);
                            // تطبيق الفلترة مباشرة عند تغيير قيمة البحث
                            const searchTerm = e.target.value.trim().toLowerCase();
                            const filtered = posts.filter(post => 
                              post.title?.toLowerCase().includes(searchTerm) || 
                              post.content?.toLowerCase().includes(searchTerm)
                            );
                            // تحديث قائمة المنشورات المفلترة
                            setFilteredPosts(filtered);
                          }}
                        />
                        <button className="search-button">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                        {searchQuery.trim() !== '' && (
                          <button
                            className="clear-search-button"
                            onClick={() => {
                              setSearchQuery('');
                              setIsSearchActive(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        )}
                      </div>
                    </div>
                    
                    <div className="posts-table-container">
                      <table className="posts-table">
                        <thead>
                          <tr>
                            <th>العنوان</th>
                            <th>القسم</th>
                            <th>التاريخ</th>
                            <th>الحالة</th>
                            <th>المشاهدات</th>
                            <th>الإجراءات</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredPosts.length === 0 ? (
                            <tr>
                              <td colSpan="6" className="no-posts-message">
                                لا توجد منشورات متاحة
                              </td>
                            </tr>
                          ) : (
                            currentPosts.map(post => (
                              <tr key={post.id} className={post.isFeatured ? 'pinned-post' : ''}>
                                <td className="post-title-cell">
                                  <div className="post-title-with-image">
                                    {post.image && (
                                      <div className="post-thumbnail">
                                        <img src={post.image} alt={post.title} />
                                      </div>
                                    )}
                                    <span>{post.title}</span>
                                  </div>
                                </td>
                                <td>
                                  {categories.find(cat => cat.id === post.categoryId)?.name || 'غير مصنف'}
                                </td>
                                <td>{new Date(post.date).toLocaleDateString('ar-SA')}</td>
                                <td>
                                  <span className={`status-badge ${post.status}`}>
                                    {post.status === 'published' ? 'منشور' : 
                                     post.status === 'draft' ? 'مسودة' : 'مجدول'}
                                  </span>
                                </td>
                                <td>{post.views}</td>
                                <td>
                                  <div className="post-actions">
                                    <button 
                                      className="view-button"
                                      onClick={() => handlePreviewPost(post)}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </button>
                                    <button 
                                      className="edit-button"
                                      onClick={() => handleEditPost(post)}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button 
                                      className="delete-button"
                                      onClick={() => handleDeletePost(post.id)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    
                    {/* التنقل بين الصفحات */}
                    {filteredPosts.length > postsPerPage && (
                      <div className="pagination">
                        <button 
                          className="page-nav-button" 
                          onClick={() => handlePageChange(1)} 
                          disabled={currentPage === 1}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                        <button 
                          className="page-nav-button" 
                          onClick={() => handlePageChange(currentPage - 1)} 
                          disabled={currentPage === 1}
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        
                        <div className="page-numbers">
                          {paginationButtons.map(pageNum => (
                            <button 
                              key={pageNum}
                              className={`page-number ${currentPage === pageNum ? 'active' : ''}`}
                              onClick={() => handlePageChange(pageNum)}
                            >
                              {pageNum}
                            </button>
                          ))}
                        </div>
                        
                        <button 
                          className="page-nav-button" 
                          onClick={() => handlePageChange(currentPage + 1)} 
                          disabled={currentPage === totalPages}
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button 
                          className="page-nav-button" 
                          onClick={() => handlePageChange(totalPages)} 
                          disabled={currentPage === totalPages}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </button>
                      </div>
                    )}
                  </div>
                )}
                
                {subTabView === 'categories' && (
                  <div>
                    {/* نموذج إضافة قسم جديد */}
                    <div className="category-form">
                      <h3>إضافة قسم جديد</h3>
                      <div className="form-row">
                        <div className="form-group">
                          <label htmlFor="categoryName">اسم القسم</label>
                          <input
                            type="text"
                            id="categoryName"
                            placeholder="أدخل اسم القسم"
                            value={newCategory.name}
                            onChange={(e) => setNewCategory({...newCategory, name: e.target.value})}
                          />
                        </div>
                        <button 
                          className="add-category-button"
                          onClick={handleAddCategory}
                          disabled={!newCategory.name.trim()}
                        >
                          إضافة قسم
                        </button>
                      </div>
                    </div>
                    
                    {/* عرض الأقسام الحالية */}
                    <div className="categories-list-container">
                      <h3>الأقسام الحالية</h3>
                      {categories.length === 0 ? (
                        <p className="no-categories-message">لا توجد أقسام حالياً</p>
                      ) : (
                        <div className="categories-table-container">
                          <table className="categories-table">
                            <thead>
                              <tr>
                                <th>اسم القسم</th>
                                <th>عدد المنشورات</th>
                                <th>الإجراءات</th>
                              </tr>
                            </thead>
                            <tbody>
                              {categories.map(category => (
                                <tr key={category.id} className={editingCategory?.id === category.id ? 'editing' : ''}>
                                  <td>
                                    {editingCategory?.id === category.id ? (
                                      <input
                                        type="text"
                                        value={editingCategory.name}
                                        onChange={(e) => setEditingCategory({...editingCategory, name: e.target.value})}
                                        autoFocus
                                      />
                                    ) : (
                                      category.name
                                    )}
                                  </td>
                                  <td>{getPostsCountByCategory(category.id)}</td>
                                  <td>
                                    <div className="category-actions">
                                      {editingCategory?.id === category.id ? (
                                        <>
                                          <button 
                                            className="save-button"
                                            onClick={handleSaveCategory}
                                          >
                                            <FontAwesomeIcon icon={faSave} />
                                          </button>
                                          <button 
                                            className="cancel-button"
                                            onClick={() => setEditingCategory(null)}
                                          >
                                            <FontAwesomeIcon icon={faTimes} />
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button 
                                            className="edit-button"
                                            onClick={() => handleEditCategoryClick(category)}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                          </button>
                                          <button 
                                            className="delete-button"
                                            onClick={() => handleDeleteCategory(category.id)}
                                            disabled={getPostsCountByCategory(category.id) > 0}
                                            title={getPostsCountByCategory(category.id) > 0 ? 'لا يمكن حذف قسم يحتوي على منشورات' : ''}
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        
      case 'comments':
        return (
          <div className="admin-panel-section">
            <h2>إدارة التعليقات</h2>
            
            <div className="comments-filters">
              <div className="search-bar">
                <input
                  type="text"
                  className="search-input"
                  placeholder="بحث في التعليقات..."
                  value={commentSearchQuery}
                  onChange={(e) => {
                    setCommentSearchQuery(e.target.value);
                    setIsCommentSearchActive(true);
                    // نطبق الفلترة عند التغيير عبر useEffect المرتبط أعلاه
                  }}
                />
                <button className="search-button">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
                {commentSearchQuery.trim() !== '' && (
                  <button
                    className="clear-search-button"
                    onClick={() => {
                      setCommentSearchQuery('');
                      setIsCommentSearchActive(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
              
              <div className="filter-options">
                <select 
                  className="modern-select"
                  value={commentFilterStatus}
                  onChange={(e) => {
                    setCommentFilterStatus(e.target.value);
                  }}
                >
                  <option value="all">جميع التعليقات</option>
                  <option value="pending">قيد الانتظار</option>
                  <option value="approved">موافق عليها</option>
                  <option value="rejected">مرفوضة</option>
                </select>
              </div>
            </div>
            
            <div className="comments-table-container">
              {filteredComments.length === 0 ? (
                <div className="no-comments-message">
                  لا توجد تعليقات متاحة
                </div>
              ) : (
                <div className="comments-list">
                  {currentComments.map(comment => (
                    <div key={comment.id} className="comment-card">
                      <div className="comment-header">
                        <div className="comment-info">
                          <span className="author-name">{comment.authorName}</span>
                          <span className="comment-date">{new Date(comment.date).toLocaleDateString('ar-SA')}</span>
                          <span className={`status-badge ${comment.status}`}>
                            {comment.status === 'pending' ? 'قيد الانتظار' : 
                            comment.status === 'approved' ? 'تمت الموافقة' : 'مرفوض'}
                          </span>
                        </div>
                        <div className="post-info">
                          <span>المنشور: {comment.postTitle}</span>
                          <span>القسم: {comment.categoryName}</span>
                        </div>
                      </div>
                      
                      <div className="comment-content">
                        {comment.content}
                      </div>
                      
                      <div className="comment-actions">
                        {comment.status === 'pending' ? (
                          <>
                            <button className="approve-button" onClick={() => approveComment(comment.id)}>
                              <FontAwesomeIcon icon={faCheck} /> قبول
                            </button>
                            <button className="reject-button" onClick={() => rejectComment(comment.id)}>
                              <FontAwesomeIcon icon={faBan} /> رفض
                            </button>
                          </>
                        ) : comment.status === 'approved' ? (
                          <button className="view-post-button">
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> عرض في المنشور
                          </button>
                        ) : null}
                        
                        <button className="delete-button" onClick={() => deleteComment(comment.id)}>
                          <FontAwesomeIcon icon={faTrash} /> حذف
                        </button>
                      </div>
                      
                      {/* عرض الردود على التعليق إن وجدت */}
                      {comment.replies && comment.replies.length > 0 && (
                        <div className="comment-replies">
                          <h4>الردود ({comment.replies.length})</h4>
                          {comment.replies.map(reply => (
                            <div key={reply.id} className="reply-card">
                              <div className="reply-header">
                                <div className="reply-info">
                                  <span className="author-name">{reply.authorName}</span>
                                  <span className="reply-date">{new Date(reply.date).toLocaleDateString('ar-SA')}</span>
                                  <span className={`status-badge ${reply.status}`}>
                                    {reply.status === 'pending' ? 'قيد الانتظار' : 
                                    reply.status === 'approved' ? 'تمت الموافقة' : 'مرفوض'}
                                  </span>
                                </div>
                              </div>
                              
                              <div className="reply-content">
                                {reply.content}
                              </div>
                              
                              <div className="reply-parent">
                                <strong>ردًا على:</strong> {comment.content.length > 50 ? 
                                  `${comment.content.substring(0, 50)}...` : comment.content}
                              </div>
                              
                              <div className="reply-actions">
                                {reply.status === 'pending' ? (
                                  <>
                                    <button className="approve-button" onClick={() => approveComment(reply.id, true, comment.id)}>
                                      <FontAwesomeIcon icon={faCheck} /> قبول
                                    </button>
                                    <button className="reject-button" onClick={() => rejectComment(reply.id, true, comment.id)}>
                                      <FontAwesomeIcon icon={faBan} /> رفض
                                    </button>
                                  </>
                                ) : reply.status === 'approved' ? (
                                  <button className="view-post-button">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} /> عرض في المنشور
                                  </button>
                                ) : null}
                                
                                <button className="delete-button" onClick={() => deleteComment(reply.id, true, comment.id)}>
                                  <FontAwesomeIcon icon={faTrash} /> حذف
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              
              {/* التنقل بين صفحات التعليقات */}
              {filteredComments.length > commentsPerPage && (
                <div className="pagination">
                  <button 
                    className="page-nav-button" 
                    onClick={() => handleCommentPageChange(1)} 
                    disabled={currentCommentPage === 1}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </button>
                  <button 
                    className="page-nav-button" 
                    onClick={() => handleCommentPageChange(currentCommentPage - 1)} 
                    disabled={currentCommentPage === 1}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                  
                  <div className="page-numbers">
                    {commentPaginationButtons.map(pageNum => (
                      pageNum === '...' ? (
                        <span key={`dots-${pageNum}`} className="page-dots">...</span>
                      ) : (
                        <button 
                          key={pageNum}
                          className={`page-number ${currentCommentPage === pageNum ? 'active' : ''}`}
                          onClick={() => handleCommentPageChange(pageNum)}
                        >
                          {pageNum}
                        </button>
                      )
                    ))}
                  </div>
                  
                  <button 
                    className="page-nav-button" 
                    onClick={() => handleCommentPageChange(currentCommentPage + 1)} 
                    disabled={currentCommentPage === commentPageCount}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <button 
                    className="page-nav-button" 
                    onClick={() => handleCommentPageChange(commentPageCount)} 
                    disabled={currentCommentPage === commentPageCount}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                </div>
              )}
            </div>
          </div>
        );
        
      case 'newsletter':
        return (
          <div className="admin-panel-section">
            <h2>النشرة البريدية</h2>
            <div className="newsletter-section">
              <div className="newsletter-stats">
                <h3>إحصائيات النشرة البريدية</h3>
                <div className="stat-box">
                  <div className="stat-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className="stat-info">
                    <div className="stat-number">2,563</div>
                    <div className="stat-label">عدد المشتركين</div>
                  </div>
                </div>
                <div className="stat-box">
                  <div className="stat-icon">
                    <FontAwesomeIcon icon={faMousePointer} />
                  </div>
                  <div className="stat-info">
                    <div className="stat-number">48.5%</div>
                    <div className="stat-label">معدل الفتح</div>
                  </div>
                </div>
                <div className="stat-box">
                  <div className="stat-icon">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </div>
                  <div className="stat-info">
                    <div className="stat-number">15</div>
                    <div className="stat-label">نشرات مرسلة</div>
                  </div>
                </div>
              </div>
              
              <div className="newsletter-compose">
                <h3>إرسال نشرة جديدة</h3>
                <div className="test-form">
                  <div className="form-group">
                    <label>عنوان النشرة</label>
                    <input type="text" className="form-input" placeholder="أدخل عنوان النشرة" />
                  </div>
                  
                  <div className="form-group">
                    <label>محتوى النشرة</label>
                    <ReactQuill 
                      className="newsletter-editor"
                      placeholder="محتوى النشرة البريدية..."
                      modules={{
                        toolbar: [
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1' }],
                          ['link', 'image'],
                          ['clean']
                        ],
                      }}
                      formats={[
                        'header',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent',
                        'link', 'image'
                      ]}
                    />
                  </div>
                  
                  <div className="form-group">
                    <label>المستلمون</label>
                    <select className="form-select">
                      <option value="all">جميع المشتركين</option>
                      <option value="active">المشتركين النشطين فقط</option>
                      <option value="inactive">المشتركين غير النشطين</option>
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label>جدولة الإرسال</label>
                    <div className="schedule-options">
                      <div className="radio-option">
                        <input type="radio" id="send-now" name="schedule" value="now" defaultChecked />
                        <label htmlFor="send-now">إرسال الآن</label>
                      </div>
                      <div className="radio-option">
                        <input type="radio" id="send-later" name="schedule" value="later" />
                        <label htmlFor="send-later">جدولة للإرسال لاحقًا</label>
                      </div>
                    </div>
                    
                    <div className="form-group">
                      <label>تاريخ ووقت الإرسال</label>
                      <input type="datetime-local" className="form-input" />
                    </div>
                    
                    <button className="submit-button">
                      إرسال النشرة البريدية
                    </button>
                  </div>
                </div>
                
                <div className="newsletter-history">
                  <h3>سجل النشرات السابقة</h3>
                  <div className="table-container">
                    <table className="posts-table">
                      <thead>
                        <tr>
                          <th>العنوان</th>
                          <th>تاريخ الإرسال</th>
                          <th>المستلمون</th>
                          <th>معدل الفتح</th>
                          <th>الإجراءات</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>تحديث الموقع الجديد</td>
                          <td>20/08/2023</td>
                          <td>1,245</td>
                          <td>53%</td>
                          <td>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button className="table-action-btn delete-btn">
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>المحتوى الجديد هذا الشهر</td>
                          <td>15/07/2023</td>
                          <td>1,198</td>
                          <td>48%</td>
                          <td>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button className="table-action-btn delete-btn">
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>إعلان عن فعاليات قادمة</td>
                          <td>01/07/2023</td>
                          <td>1,320</td>
                          <td>62%</td>
                          <td>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            <button className="table-action-btn">
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button className="table-action-btn delete-btn">
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        
      case 'accounts':
        return (
          <div className="admin-panel-section">
            <div className="centered-login-container">
              {!isLoggedIn ? (
                // صفحة تسجيل الدخول
                <div className="login-container">
                  <div className="login-form-wrapper">
                    <h3>تسجيل الدخول للمسؤول</h3>
                    {loginError && <div className="error-message">{loginError}</div>}
                    <form onSubmit={handleLogin} className="login-form">
                      <div className="form-group">
                        <label htmlFor="username">اسم المستخدم</label>
                        <input 
                          type="text" 
                          id="username" 
                          value={loginUsername} 
                          onChange={(e) => setLoginUsername(e.target.value)} 
                          required 
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">كلمة المرور</label>
                        <input 
                          type="password" 
                          id="password" 
                          value={loginPassword} 
                          onChange={(e) => setLoginPassword(e.target.value)} 
                          required 
                        />
                      </div>
                      <button type="submit" className="login-button">
                        <FontAwesomeIcon icon={faSignOutAlt} /> تسجيل الدخول
                      </button>
                    </form>
                  </div>
                </div>
              ) : (
                // صفحة إدارة الحسابات
                <div className="accounts-management">
                  <div className="accounts-tabs">
                    <button 
                      className={`account-tab-btn ${accountSection === 'manageAccounts' ? 'active' : ''}`}
                      onClick={() => setAccountSection('manageAccounts')}
                    >
                      <FontAwesomeIcon icon={faUser} /> إدارة الحسابات
                    </button>
                    <button 
                      className={`account-tab-btn ${accountSection === 'addOwner' ? 'active' : ''}`}
                      onClick={() => setAccountSection('addOwner')}
                    >
                      <FontAwesomeIcon icon={faUserPlus} /> إنشاء حساب مالك
                    </button>
                    <button 
                      className={`account-tab-btn ${accountSection === 'addAdmin' ? 'active' : ''}`}
                      onClick={() => setAccountSection('addAdmin')}
                    >
                      <FontAwesomeIcon icon={faUserPlus} /> إنشاء حساب مسؤول
                    </button>
                  </div>
                  
                  {accountSection === 'manageAccounts' && (
                    <div className="accounts-section">
                      <div className="accounts-section-header">
                        <input type="text" className="search-input" placeholder="بحث عن حساب..." />
                      </div>
                      
                      {users.length > 0 ? (
                        <div className="account-list">
                          {users.map(user => (
                            <div className="account-card" key={user.id}>
                              <div className="account-header">
                                <div className="account-avatar">
                                  <FontAwesomeIcon icon={faUser} />
                                </div>
                                <div className="account-info">
                                  <h4>{user.displayName}</h4>
                                  <p>{user.username}</p>
                                  <span className={`account-role role-${user.role}`}>
                                    {user.role === 'owner' ? 'مالك' : 'مسؤول'}
                                  </span>
                                </div>
                              </div>
                              <div className="account-stats">
                                <div className="stat-item">
                                  <span className="stat-label">المنشورات</span>
                                  <span className="stat-value">{user.posts}</span>
                                </div>
                                <div className="stat-item">
                                  <span className="stat-label">آخر تسجيل دخول</span>
                                  <span className="stat-value">{user.lastLogin}</span>
                                </div>
                              </div>
                              <div className="account-actions">
                                <button className="account-action-btn">
                                  <FontAwesomeIcon icon={faEdit} /> تعديل
                                </button>
                                <button className="account-action-btn">
                                  <FontAwesomeIcon icon={faKey} /> إعادة تعيين كلمة المرور
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="no-accounts-message">
                          <p>لا توجد حسابات مضافة. قم بإنشاء حساب جديد من خلال الأزرار أعلاه.</p>
                        </div>
                      )}
                    </div>
                  )}
                  
                  {(accountSection === 'addOwner' || accountSection === 'addAdmin') && (
                    <div className="create-account-section">
                      <h3>
                        {accountSection === 'addOwner' ? 'إنشاء حساب مالك' : 'إنشاء حساب مسؤول'}
                      </h3>
                      
                      {loginError && <div className="error-message">{loginError}</div>}
                      
                      <form onSubmit={handleCreateAccount} className="create-account-form">
                        <div className="form-group">
                          <label htmlFor="newUsername">اسم المستخدم</label>
                          <input 
                            type="text" 
                            id="newUsername" 
                            value={newUsername} 
                            onChange={(e) => setNewUsername(e.target.value)} 
                            required 
                          />
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="displayName">اسم العرض</label>
                          <input 
                            type="text" 
                            id="displayName" 
                            value={displayName} 
                            onChange={(e) => setDisplayName(e.target.value)} 
                            placeholder="الاسم الذي سيظهر للمستخدمين"
                          />
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="newPassword">كلمة المرور</label>
                          <input 
                            type="password" 
                            id="newPassword" 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)} 
                            required 
                          />
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                          <input 
                            type="password" 
                            id="confirmPassword" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required 
                          />
                        </div>
                        
                        {accountSection === 'addAdmin' && (
                          <div className="permissions-section">
                            <h4>الصلاحيات</h4>
                            <div className="permissions-list">
                              <div className="permission-item">
                                <input 
                                  type="checkbox" 
                                  id="createCategory" 
                                  checked={selectedPermissions.createCategory}
                                  onChange={() => setSelectedPermissions({
                                    ...selectedPermissions,
                                    createCategory: !selectedPermissions.createCategory
                                  })}
                                />
                                <label htmlFor="createCategory">إنشاء قسم جديد</label>
                              </div>
                              
                              <div className="permission-item">
                                <input 
                                  type="checkbox" 
                                  id="deleteCategory" 
                                  checked={selectedPermissions.deleteCategory}
                                  onChange={() => setSelectedPermissions({
                                    ...selectedPermissions,
                                    deleteCategory: !selectedPermissions.deleteCategory
                                  })}
                                />
                                <label htmlFor="deleteCategory">حذف قسم</label>
                              </div>
                              
                              <div className="permission-item">
                                <input 
                                  type="checkbox" 
                                  id="createPost" 
                                  checked={selectedPermissions.createPost}
                                  onChange={() => setSelectedPermissions({
                                    ...selectedPermissions,
                                    createPost: !selectedPermissions.createPost
                                  })}
                                />
                                <label htmlFor="createPost">إنشاء منشور</label>
                              </div>
                              
                              <div className="permission-item">
                                <input 
                                  type="checkbox" 
                                  id="deletePost" 
                                  checked={selectedPermissions.deletePost}
                                  onChange={() => setSelectedPermissions({
                                    ...selectedPermissions,
                                    deletePost: !selectedPermissions.deletePost
                                  })}
                                />
                                <label htmlFor="deletePost">حذف منشور</label>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        <button 
                          type="submit" 
                          className="create-account-button"
                          onClick={() => setAccountType(accountSection === 'addOwner' ? 'owner' : 'admin')}
                        >
                          <FontAwesomeIcon icon={faUserPlus} /> إنشاء الحساب
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
        
      case 'analytics':
        return (
          <div className="admin-panel-section">
            <h2>تحليل</h2>
            
            {/* قسم تحليل الزيارات */}
            <div className="analytics-section">
              <div className="analytics-header">
                <h3 className="analytics-title">
                  <FontAwesomeIcon icon={faChartLine} /> إحصائيات الزيارات
                </h3>
                <p className="analytics-subtitle">متابعة الزيارات اليومية والشهرية والسنوية للموقع</p>
                <button 
                  className="reset-visits-button"
                  onClick={handleResetVisits}
                  title="تصفير عداد الزيارات"
                >
                  <FontAwesomeIcon icon={faTrash} /> تصفير
                </button>
              </div>

              <div className="analytics-content">
                <div className="summary-cards">
                  <div className="summary-card">
                    <FontAwesomeIcon icon={faEye} className="summary-icon" />
                    <div className="summary-label">إجمالي الزيارات</div>
                    <div className="summary-value">{visitStats.all.totalVisits.toLocaleString()}</div>
                  </div>
                  <div className="summary-card">
                    <FontAwesomeIcon icon={faCalendarDay} className="summary-icon" />
                    <div className="summary-label">المتوسط اليومي</div>
                    <div className="summary-value">{visitStats.all.avgDaily.toLocaleString()}</div>
                  </div>
                  <div className="summary-card">
                    <FontAwesomeIcon icon={faCalendarAlt} className="summary-icon" />
                    <div className="summary-label">المتوسط الشهري</div>
                    <div className="summary-value">{visitStats.all.avgMonthly.toLocaleString()}</div>
                  </div>
                  <div className="summary-card">
                    <FontAwesomeIcon icon={faHistory} className="summary-icon" />
                    <div className="summary-label">أعلى يوم زيارة</div>
                    <div className="summary-value">{visitStats.all.highestDay.count.toLocaleString()}</div>
                    <div className="summary-date">{visitStats.all.highestDay.date}</div>
                  </div>
                </div>

                <div className="analytics-tabs">
                  <button 
                    className={`analytics-tab ${activeAnalyticsTab === 'day' ? 'active' : ''}`}
                    onClick={() => setActiveAnalyticsTab('day')}
                  >
                    <FontAwesomeIcon icon={faCalendarDay} />
                    اليوم
                  </button>
                  <button 
                    className={`analytics-tab ${activeAnalyticsTab === 'month' ? 'active' : ''}`}
                    onClick={() => setActiveAnalyticsTab('month')}
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    الشهر
                  </button>
                  <button 
                    className={`analytics-tab ${activeAnalyticsTab === 'year' ? 'active' : ''}`}
                    onClick={() => setActiveAnalyticsTab('year')}
                  >
                    <FontAwesomeIcon icon={faCalendarWeek} />
                    السنة
                  </button>
                </div>

                <div className="analytics-chart-container">
                  {renderChart(visitStats[activeAnalyticsTab])}
                </div>

                <div className="analytics-footer">
                  <FontAwesomeIcon icon={faEye} /> آخر تحديث: {new Date().toLocaleString('ar-EG')}
                </div>
              </div>
            </div>

            <div className="settings-section mt-4">
              <h3>تخصيص لوحة التحكم</h3>
              <p className="drag-instruction">اسحب الأزرار لإعادة ترتيبها في القائمة</p>
              
              <ul className="sortable-list">
                {adminTabs
                  .filter(tab => tab.visible)
                  .sort((a, b) => a.order - b.order)
                  .map((tab, index) => (
                    <li
                      key={tab.id}
                      className="sortable-item"
                      draggable="true"
                      onDragStart={(e) => startDrag(e, 'settings', index)}
                      onDragOver={dragOver}
                      onDragLeave={dragLeave}
                      onDrop={(e) => drop(e, 'settings', index)}
                      onDragEnd={endDrag}
                    >
                      <div className="drag-handle">
                        <FontAwesomeIcon icon={faGripVertical} />
                      </div>
                      
                      <div className="tab-preview">
                        <FontAwesomeIcon icon={tab.icon} className="tab-icon" />
                        <span className="item-name">{tab.title}</span>
                      </div>
                      
                      <div className="item-visibility">
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            checked={tab.visible}
                            onChange={() => {
                              const updatedTabs = adminTabs.map(t => 
                                t.id === tab.id ? {...t, visible: !t.visible} : t
                              );
                              setAdminTabs(updatedTabs);
                              localStorage.setItem('adminTabs', JSON.stringify(updatedTabs));
                            }}
                          />
                          <span className="slider"></span>
                        </label>
                        <span className="visibility-label">
                          {tab.visible ? 'ظاهر' : 'مخفي'}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        );
        
      case 'api':
        return (
          <div className="admin-panel-section">
            <h2>API</h2>
            <div className="api-container">
              <div className="api-description">
                <h3>واجهة برمجة تطبيقات الموقع</h3>
                <p>استخدم API الخاص بموقع El Gedid لإضافة منشورات جديدة من أي تطبيق خارجي. يمكنك إنشاء منشورات كاملة مع عنوان ونص ووسائط واختيار القسم المناسب.</p>
              </div>
              
              <div className="api-key-section">
                <h3>مفتاح API الخاص بك</h3>
                <div className="api-key-container">
                  <input type="text" className="api-key-input" value="sk_live_AwanApiKey2025XYZ123456789" readOnly />
                  <button className="copy-button">
                    <FontAwesomeIcon icon={faCopy} /> نسخ
                  </button>
                  <button className="refresh-button">
                    <FontAwesomeIcon icon={faSync} /> تجديد
                  </button>
                </div>
                <p className="api-warning">لا تشارك مفتاح API الخاص بك مع أي شخص. في حالة تسربه، قم بتجديده على الفور.</p>
              </div>
              
              <div className="api-documentation">
                <h3>طريقة الاستخدام</h3>
                <div className="api-endpoint">
                  <h4>نقطة النهاية (Endpoint)</h4>
                  <code>https://api.awan.com/v1/posts</code>
                </div>
                
                <div className="api-method">
                  <h4>طريقة الطلب</h4>
                  <code>POST</code>
                </div>
                
                <div className="api-params">
                  <h4>المعلمات المطلوبة</h4>
                  <table className="api-table">
                    <thead>
                      <tr>
                        <th>المعلمة</th>
                        <th>النوع</th>
                        <th>الوصف</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>title</td>
                        <td>string</td>
                        <td>عنوان المنشور</td>
                      </tr>
                      <tr>
                        <td>content</td>
                        <td>string</td>
                        <td>محتوى المنشور (يدعم HTML)</td>
                      </tr>
                      <tr>
                        <td>category_id</td>
                        <td>string</td>
                        <td>رقم معرف القسم</td>
                      </tr>
                      <tr>
                        <td>image</td>
                        <td>file</td>
                        <td>ملف الصورة (اختياري)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="api-example">
                  <h4>مثال على الاستخدام</h4>
                  <pre className="code-example">
{`curl -X POST https://api.awan.com/v1/posts \\
  -H "Authorization: Bearer sk_live_AwanApiKey2025XYZ123456789" \\
  -F "title=عنوان المنشور الجديد" \\
  -F "content=محتوى المنشور الجديد بتنسيق <b>HTML</b>" \\
  -F "category_id=1" \\
  -F "image=@/path/to/image.jpg"`}
                  </pre>
                </div>
                
                <div className="api-response">
                  <h4>مثال على الاستجابة</h4>
                  <pre className="code-example">
{`{
  "status": "success",
  "data": {
    "post_id": "12345",
    "title": "عنوان المنشور الجديد",
    "url": "https://awan.com/posts/12345"
  }
}`}
                  </pre>
                </div>
              </div>
              
              <div className="test-api-section">
                <h3>اختبار API</h3>
                <div className="test-form">
                  <div className="form-group">
                    <label>عنوان المنشور</label>
                    <input type="text" className="form-input" placeholder="أدخل عنوان المنشور" />
                  </div>
                  
                  <div className="form-group">
                    <label>القسم</label>
                    <select className="form-select">
                      <option value="">اختر القسم</option>
                      {categories && categories.length > 0 ? (
                        categories.map(category => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))
                      ) : null}
                    </select>
                  </div>
                  
                  <div className="form-group">
                    <label>المحتوى</label>
                    <textarea className="form-textarea" placeholder="أدخل محتوى المنشور"></textarea>
                  </div>
                  
                  <div className="form-group">
                    <label>الصورة</label>
                    <input type="file" className="form-input" accept="image/*" />
                  </div>
                  
                  <button className="submit-button">
                    اختبار API
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        
      case 'logout':
        return (
          <div className="admin-panel-section">
            <h2>تسجيل الخروج</h2>
            <p>محتوى هذا القسم قيد التطوير.</p>
          </div>
        );
        
      default:
        return <div className="admin-panel-section">
          <h2>{adminTabs.find(tab => tab.id === activeTab)?.title || 'القسم غير موجود'}</h2>
          <p>محتوى هذا القسم قيد التطوير.</p>
        </div>;
    }
  };

  useEffect(() => {
    const handleToggleEditTabs = () => {
      setEditingTabs(prev => !prev);
    };

    window.addEventListener('toggleEditTabs', handleToggleEditTabs);
    return () => {
      window.removeEventListener('toggleEditTabs', handleToggleEditTabs);
    };
  }, []);

  // وظيفة لحفظ المنشور
  const handleSavePost = async () => {
    setIsLoading(true);
    
    try {
      // منع الحفظ إذا كان العنوان أو المحتوى فارغين
      if (!newPostTitle.trim() || !newPostContent.trim()) {
        alert('يرجى إدخال عنوان ومحتوى للمنشور');
        setIsLoading(false);
        return;
      }

      // إنشاء كائن المنشور
      const newPost = {
        title: newPostTitle,
        content: newPostContent,
        excerpt: newPostExcerpt,
        category_id: newPostCategory,
        status: 'draft',
        featured_image: newPostImage,
      };
      
      // إضافة المنشور إلى قائمة المنشورات
      const updatedPosts = [...posts, newPost];
      
      // حفظ المنشورات في localStorage
      localStorage.setItem('posts', JSON.stringify(updatedPosts));
      
      // إعادة تعيين النموذج
      resetPostForm();
      
      // إظهار رسالة نجاح
      alert('تم حفظ المنشور بنجاح!');
      
      // تغيير التبويب إلى إدارة المنشورات
      setActiveTab('postsAndCategories');
    } catch (error) {
      console.error('حدث خطأ أثناء حفظ المنشور:', error);
      alert('حدث خطأ أثناء حفظ المنشور. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };

  // إصلاح وظيفة تبديل تثبيت المنشور
  const handleTogglePin = async (postId) => {
    try {
      // البحث عن المنشور
      const post = posts.find(p => p.id === postId);
      if (!post) return;
      
      // تبديل حالة التثبيت
      const updatedPostData = { 
        ...post, 
        is_featured: !post.is_featured 
      };
      
      // إرسال الطلب إلى API
      await api.updatePost(postId, updatedPostData);
      
      // إعادة تحميل المنشورات من السيرفر
      const updatedPosts = await api.getAllPosts();
      setPosts(updatedPosts);
      
    } catch (error) {
      console.error('خطأ في تحديث حالة تثبيت المنشور:', error);
      alert('حدث خطأ أثناء تثبيت المنشور');
    }
  };
  
  // تنفيذ وظائف إدارة التعليقات
  useEffect(() => {
    // استرجاع التعليقات المحفوظة من التخزين المحلي أو إنشاء بيانات تجريبية
    const savedComments = localStorage.getItem('comments');
    
    if (savedComments) {
      try {
        const parsedComments = JSON.parse(savedComments);
        if (Array.isArray(parsedComments) && parsedComments.length > 0) {
          setComments(parsedComments);
          setFilteredComments(parsedComments);
        } else {
          // إذا كان التخزين المحلي فارغًا، قم بإنشاء بيانات تجريبية
          createSampleComments();
        }
      } catch (error) {
        console.error('خطأ في تحليل التعليقات المحفوظة:', error);
        createSampleComments();
      }
    } else {
      // إذا لم يكن هناك تعليقات محفوظة، قم بإنشاء بيانات تجريبية
      createSampleComments();
    }
  }, []);

  // وظيفة لإنشاء بيانات تعليقات تجريبية
  const createSampleComments = () => {
    const sampleComments = [
      {
        id: '1',
        postId: '1',
        postTitle: 'أول مقال في المدونة',
        categoryId: '1',
        categoryName: 'أخبار',
        authorName: 'أحمد محمد',
        authorEmail: 'ahmed@example.com',
        content: 'هذا تعليق رائع جدًا على المنشور!',
        date: new Date().toISOString(),
        status: 'pending', // pending, approved, rejected
        parentId: null,
        replies: []
      },
      {
        id: '2',
        postId: '1',
        postTitle: 'أول مقال في المدونة',
        categoryId: '1',
        categoryName: 'أخبار',
        authorName: 'سارة أحمد',
        authorEmail: 'sara@example.com',
        content: 'محتوى المقال مفيد جدًا، شكرًا للكاتب!',
        date: new Date(Date.now() - 86400000).toISOString(), // بالأمس
        status: 'approved',
        parentId: null,
        replies: [
          {
            id: '3',
            postId: '1',
            postTitle: 'أول مقال في المدونة',
            categoryId: '1',
            categoryName: 'أخبار',
            authorName: 'محمد علي',
            authorEmail: 'mohamed@example.com',
            content: 'أنا أتفق معك تمامًا!',
            date: new Date(Date.now() - 43200000).toISOString(), // قبل 12 ساعة
            status: 'pending',
            parentId: '2'
          }
        ]
      },
      {
        id: '4',
        postId: '2',
        postTitle: 'كيفية تعلم البرمجة',
        categoryId: '2',
        categoryName: 'تقنية',
        authorName: 'خالد عبدالله',
        authorEmail: 'khaled@example.com',
        content: 'هل يمكنك اقتراح بعض الموارد لتعلم جافا سكريبت؟',
        date: new Date(Date.now() - 172800000).toISOString(), // قبل يومين
        status: 'approved',
        parentId: null,
        replies: []
      }
    ];
    
    setComments(sampleComments);
    setFilteredComments(sampleComments);
    localStorage.setItem('comments', JSON.stringify(sampleComments));
  };

  // وظيفة للموافقة على تعليق
  const approveComment = (commentId, isReply = false, parentId = null) => {
    let updatedComments = [...comments];
    
    if (isReply && parentId) {
      // إذا كان ردًا، ابحث عن التعليق الأصلي وعدّل الرد
      updatedComments = updatedComments.map(comment => {
        if (comment.id === parentId) {
          const updatedReplies = comment.replies.map(reply => 
            reply.id === commentId ? { ...reply, status: 'approved' } : reply
          );
          return { ...comment, replies: updatedReplies };
        }
        return comment;
      });
    } else {
      // إذا كان تعليقًا رئيسيًا
      updatedComments = updatedComments.map(comment =>
        comment.id === commentId ? { ...comment, status: 'approved' } : comment
      );
    }
    
    setComments(updatedComments);
    applyCommentFilters(updatedComments);
    localStorage.setItem('comments', JSON.stringify(updatedComments));
  };

  // وظيفة لرفض تعليق
  const rejectComment = (commentId, isReply = false, parentId = null) => {
    let updatedComments = [...comments];
    
    if (isReply && parentId) {
      // إذا كان ردًا، ابحث عن التعليق الأصلي وعدّل الرد
      updatedComments = updatedComments.map(comment => {
        if (comment.id === parentId) {
          const updatedReplies = comment.replies.map(reply => 
            reply.id === commentId ? { ...reply, status: 'rejected' } : reply
          );
          return { ...comment, replies: updatedReplies };
        }
        return comment;
      });
    } else {
      // إذا كان تعليقًا رئيسيًا
      updatedComments = updatedComments.map(comment =>
        comment.id === commentId ? { ...comment, status: 'rejected' } : comment
      );
    }
    
    setComments(updatedComments);
    applyCommentFilters(updatedComments);
    localStorage.setItem('comments', JSON.stringify(updatedComments));
  };

  // وظيفة لحذف تعليق
  const deleteComment = (commentId, isReply = false, parentId = null) => {
    let updatedComments = [...comments];
    
    if (isReply && parentId) {
      // إذا كان ردًا، ابحث عن التعليق الأصلي واحذف الرد
      updatedComments = updatedComments.map(comment => {
        if (comment.id === parentId) {
          const updatedReplies = comment.replies.filter(reply => reply.id !== commentId);
          return { ...comment, replies: updatedReplies };
        }
        return comment;
      });
    } else {
      // إذا كان تعليقًا رئيسيًا، احذفه وكل الردود عليه
      updatedComments = updatedComments.filter(comment => comment.id !== commentId);
    }
    
    setComments(updatedComments);
    applyCommentFilters(updatedComments);
    localStorage.setItem('comments', JSON.stringify(updatedComments));
  };

  // تطبيق فلاتر التعليقات
  const applyCommentFilters = (commentsData = comments) => {
    let filtered = [...commentsData];
    
    // تطبيق فلتر البحث
    if (commentSearchQuery.trim() !== '') {
      const query = commentSearchQuery.toLowerCase();
      filtered = filtered.filter(comment => 
        comment.content.toLowerCase().includes(query) || 
        comment.authorName.toLowerCase().includes(query) ||
        comment.postTitle.toLowerCase().includes(query)
      );
    }
    
    // تطبيق فلتر الحالة
    if (commentFilterStatus !== 'all') {
      filtered = filtered.filter(comment => comment.status === commentFilterStatus);
    }
    
    setFilteredComments(filtered);
    setCurrentCommentPage(1); // إعادة تعيين رقم الصفحة عند تغيير الفلاتر
  };

  // معالجة تغيير صفحة التعليقات
  const handleCommentPageChange = (pageNumber) => {
    setCurrentCommentPage(pageNumber);
  };

  // حساب عدد صفحات التعليقات
  const commentPageCount = Math.ceil(filteredComments.length / commentsPerPage);
  
  // إنشاء أزرار التنقل بين صفحات التعليقات
  const commentPaginationButtons = useMemo(() => {
    const buttons = [];
    const maxButtons = 5;
    
    if (commentPageCount <= maxButtons) {
      // إذا كان العدد الإجمالي للصفحات أقل من أو يساوي الحد الأقصى للأزرار، أظهر كل الأزرار
      for (let i = 1; i <= commentPageCount; i++) {
        buttons.push(i);
      }
    } else {
      // إذا كان العدد الإجمالي للصفحات أكبر من الحد الأقصى، نعرض مجموعة منها
      if (currentCommentPage <= 3) {
        // إذا كنا في البداية، نعرض الـ 4 صفحات الأولى ثم ...
        for (let i = 1; i <= 4; i++) {
          buttons.push(i);
        }
        buttons.push('...');
        buttons.push(commentPageCount);
      } else if (currentCommentPage >= commentPageCount - 2) {
        // إذا كنا في النهاية، نعرض الصفحة الأولى ثم ... ثم آخر 4 صفحات
        buttons.push(1);
        buttons.push('...');
        for (let i = commentPageCount - 3; i <= commentPageCount; i++) {
          buttons.push(i);
        }
      } else {
        // إذا كنا في المنتصف، نعرض الصفحة الأولى ثم ... ثم الصفحة الحالية والصفحتين المحيطتين بها ثم ... ثم الصفحة الأخيرة
        buttons.push(1);
        buttons.push('...');
        for (let i = currentCommentPage - 1; i <= currentCommentPage + 1; i++) {
          buttons.push(i);
        }
        buttons.push('...');
        buttons.push(commentPageCount);
      }
    }
    
    return buttons;
  }, [currentCommentPage, commentPageCount]);
  
  // الحصول على التعليقات الحالية للصفحة الحالية
  const currentComments = useMemo(() => {
    const indexOfLastComment = currentCommentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    return filteredComments.slice(indexOfFirstComment, indexOfLastComment);
  }, [filteredComments, currentCommentPage, commentsPerPage]);

  // تطبيق فلاتر التعليقات عند تغيير البحث أو معايير الفلترة
  useEffect(() => {
    applyCommentFilters();
  }, [commentSearchQuery, commentFilterStatus]);

  // وظائف إدارة الحسابات وتسجيل الدخول/الخروج
  const handleLogin = (e) => {
    e.preventDefault();
    
    if (!loginUsername.trim() || !loginPassword.trim()) {
      setLoginError('يرجى إدخال اسم المستخدم وكلمة المرور');
      
      // إخفاء رسالة الخطأ بعد 3 ثوان
      setTimeout(() => {
        setLoginError('');
      }, 3000);
      
      return;
    }
    
    // البحث عن المستخدم في localStorage
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    const user = savedUsers.find(u => u.username === loginUsername && u.password === loginPassword);
    
    // إذا وجدنا المستخدم المسجل
    if (user) {
      setIsLoggedIn(true);
      setLoginError('');
      
      // تحديث آخر تسجيل دخول
      const updatedUsers = savedUsers.map(u => {
        if (u.username === loginUsername) {
          return { ...u, lastLogin: new Date().toLocaleDateString('ar-EG') };
        }
        return u;
      });
      
      localStorage.setItem('users', JSON.stringify(updatedUsers));
      localStorage.setItem('currentUser', JSON.stringify({
        username: user.username,
        displayName: user.displayName,
        role: user.role,
        permissions: user.permissions
      }));
      localStorage.setItem('isLoggedIn', 'true');
      
      // تحميل المستخدمين المحدثين
      setUsers(updatedUsers);
    } 
    // إذا كانت هذه أول مرة يتم فيها تشغيل النظام ولا يوجد مستخدمين
    else if (savedUsers.length === 0 && loginUsername === 'admin' && loginPassword === 'admin123') {
      // إنشاء حساب المسؤول الافتراضي
      const defaultAdmin = {
        id: Date.now(),
        username: 'admin',
        password: 'admin123',
        displayName: 'المسؤول الرئيسي',
        role: 'owner',
        permissions: {
          createCategory: true,
          deleteCategory: true,
          createPost: true,
          deletePost: true
        },
        posts: 0,
        lastLogin: new Date().toLocaleDateString('ar-EG')
      };
      
      localStorage.setItem('users', JSON.stringify([defaultAdmin]));
      localStorage.setItem('currentUser', JSON.stringify({
        username: defaultAdmin.username,
        displayName: defaultAdmin.displayName,
        role: defaultAdmin.role,
        permissions: defaultAdmin.permissions
      }));
      localStorage.setItem('isLoggedIn', 'true');
      
      setUsers([defaultAdmin]);
      setIsLoggedIn(true);
      setLoginError('');
    } else {
      setLoginError('اسم المستخدم أو كلمة المرور غير صحيحة');
      
      // إخفاء رسالة الخطأ بعد 3 ثوان
      setTimeout(() => {
        setLoginError('');
      }, 3000);
    }
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    
    // التحقق من الحقول المطلوبة
    if (!newUsername.trim() || !newPassword.trim() || !confirmPassword.trim()) {
      setLoginError('جميع الحقول المطلوبة يجب ملؤها');
      return;
    }
    
    // التحقق من أن اسم المستخدم يحتوي على أحرف إنجليزية وأرقام فقط
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    if (!usernameRegex.test(newUsername)) {
      setLoginError('اسم المستخدم يجب أن يحتوي على أحرف إنجليزية وأرقام وشرطات سفلية فقط');
      return;
    }
    
    // التحقق من طول كلمة المرور
    if (newPassword.length < 6) {
      setLoginError('كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل');
      return;
    }
    
    // التحقق من تطابق كلمة المرور
    if (newPassword !== confirmPassword) {
      setLoginError('كلمات المرور غير متطابقة');
      return;
    }
    
    // التحقق من وجود اسم المستخدم
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    if (savedUsers.some(user => user.username === newUsername)) {
      setLoginError('اسم المستخدم موجود بالفعل، يرجى اختيار اسم آخر');
      return;
    }
    
    // إنشاء الحساب الجديد
    const newUser = {
      id: Date.now(),
      username: newUsername,
      password: newPassword,  // ملاحظة: في الإنتاج، يجب تشفير كلمة المرور
      displayName: displayName || newUsername,
      role: accountType,
      permissions: selectedPermissions,
      posts: 0,
      lastLogin: new Date().toLocaleDateString('ar-EG')
    };
    
    // تحديث القائمة المحلية والتخزين
    const updatedUsers = [...savedUsers, newUser];
    setUsers(updatedUsers);
    localStorage.setItem('users', JSON.stringify(updatedUsers));
    
    // إعادة تعيين الحقول
    setNewUsername('');
    setNewPassword('');
    setConfirmPassword('');
    setDisplayName('');
    setLoginError('');
    setAccountSection('manageAccounts');
  };
  
  // دالة لحذف حساب
  const handleDeleteAccount = (userId) => {
    if (window.confirm('هل أنت متأكد من حذف هذا الحساب؟')) {
      const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const updatedUsers = savedUsers.filter(user => user.id !== userId);
      
      setUsers(updatedUsers);
      localStorage.setItem('users', JSON.stringify(updatedUsers));
    }
  };
  
  // دالة لإعادة تعيين كلمة المرور
  const handleResetPassword = (userId) => {
    const newPassword = prompt('أدخل كلمة المرور الجديدة:');
    
    if (newPassword && newPassword.length >= 6) {
      const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const updatedUsers = savedUsers.map(user => {
        if (user.id === userId) {
          return { ...user, password: newPassword };
        }
        return user;
      });
      
      setUsers(updatedUsers);
      localStorage.setItem('users', JSON.stringify(updatedUsers));
      alert('تم تغيير كلمة المرور بنجاح');
    } else if (newPassword) {
      alert('كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل');
    }
  };

// التحقق من حالة تسجيل الدخول عند تحميل الصفحة
useEffect(() => {
  const savedLoggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
  setIsLoggedIn(savedLoggedInStatus);
  
  if (!savedLoggedInStatus && activeTab !== 'accounts') {
    setActiveTab('accounts');
    setAccountSection('login');
  }
}, []); // لاحظ وجود القوس هنا للإغلاق

// عرض علامات التبويب بناءً على حالة تسجيل الدخول
const sidebarTabs = (
  <ul className="admin-tabs">
    {adminTabs
      .filter(tab => (isLoggedIn || tab.id === 'accounts') && tab.visible)
      .sort((a, b) => a.order - b.order)
      .map(tab => (
        <li
          key={tab.id}
          className={`admin-tab ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
          draggable
          onDragStart={(e) => startDrag(e, 'sidebar', adminTabs.filter(t => t.visible).findIndex(t => t.id === tab.id))}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDrop={(e) => drop(e, 'sidebar', adminTabs.filter(t => t.visible).findIndex(t => t.id === tab.id))}
          onDragEnd={endDrag}
        >
          <FontAwesomeIcon
            icon={tab.icon}
            className="tab-icon"
          />
          <span className="tab-title">{tab.title}</span>
          {editingTabs && (
            <FontAwesomeIcon
              icon={faGripVertical}
              className="move-handle"
            />
          )}
        </li>
      ))}
    
    {isLoggedIn && (
      <li className="admin-tab logout-tab" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} className="tab-icon" />
        <span className="tab-title">تسجيل الخروج</span>
      </li>
    )}
  </ul>
);

// دالة عرض محتوى قسم الحسابات
const renderAccountsContent = () => {
  return (
    <div className="admin-panel-section account-section">
      <div className="section-header">
        <h2>إدارة الحسابات</h2>
      </div>
      
      <div className="account-content">
        {!isLoggedIn ? (
          // نموذج تسجيل الدخول
          <div className="login-form-container">
            <h3>تسجيل الدخول</h3>
            <form className="login-form" onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="username">اسم المستخدم</label>
                <input 
                  type="text" 
                  id="username" 
                  value={loginUsername} 
                  onChange={(e) => setLoginUsername(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">كلمة المرور</label>
                <input 
                  type="password" 
                  id="password" 
                  value={loginPassword} 
                  onChange={(e) => setLoginPassword(e.target.value)} 
                  required 
                />
              </div>
              {loginError && <div className="error-message">{loginError}</div>}
              <button type="submit" className="login-btn">
                <FontAwesomeIcon icon={faKey} /> تسجيل الدخول
              </button>
            </form>
          </div>
        ) : (
          // إدارة الحسابات بعد تسجيل الدخول
          <div className="accounts-management">
            <div className="accounts-tabs">
              <button
                className={`account-tab-btn ${accountSection === 'addOwner' ? 'active' : ''}`}
                onClick={() => setAccountSection('addOwner')}
              >
                إضافة مالك
              </button>
              <button
                className={`account-tab-btn ${accountSection === 'addAdmin' ? 'active' : ''}`}
                onClick={() => setAccountSection('addAdmin')}
              >
                إضافة مشرف
              </button>
              <button
                className={`account-tab-btn ${accountSection === 'manageAccounts' ? 'active' : ''}`}
                onClick={() => setAccountSection('manageAccounts')}
              >
                إدارة الحسابات
              </button>
            </div>
            
            {accountSection === 'addOwner' && (
              <div className="add-account-form">
                <h3>إنشاء حساب مالك جديد</h3>
                <form onSubmit={handleCreateAccount}>
                  <div className="form-group">
                    <label htmlFor="newUsername">اسم المستخدم</label>
                    <input 
                      type="text" 
                      id="newUsername" 
                      value={newUsername} 
                      onChange={(e) => setNewUsername(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="displayName">اسم العرض</label>
                    <input 
                      type="text" 
                      id="displayName" 
                      value={displayName} 
                      onChange={(e) => setDisplayName(e.target.value)} 
                      placeholder="الاسم الذي سيظهر للمستخدمين"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="newPassword">كلمة المرور</label>
                    <input 
                      type="password" 
                      id="newPassword" 
                      value={newPassword} 
                      onChange={(e) => setNewPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                    <input 
                      type="password" 
                      id="confirmPassword" 
                      value={confirmPassword} 
                      onChange={(e) => setConfirmPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <input type="hidden" name="role" value="owner" />
                  {createAccountError && <div className="error-message">{createAccountError}</div>}
                  <button type="submit" className="add-account-btn">
                    <FontAwesomeIcon icon={faUserPlus} /> إنشاء مالك جديد
                  </button>
                </form>
              </div>
            )}
            
            {accountSection === 'addAdmin' && (
              <div className="add-account-form">
                <h3>إنشاء حساب مشرف جديد</h3>
                <form onSubmit={handleCreateAccount}>
                  <div className="form-group">
                    <label htmlFor="newUsername">اسم المستخدم</label>
                    <input 
                      type="text" 
                      id="newUsername" 
                      value={newUsername} 
                      onChange={(e) => setNewUsername(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="displayName">اسم العرض</label>
                    <input 
                      type="text" 
                      id="displayName" 
                      value={displayName} 
                      onChange={(e) => setDisplayName(e.target.value)} 
                      placeholder="الاسم الذي سيظهر للمستخدمين"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="newPassword">كلمة المرور</label>
                    <input 
                      type="password" 
                      id="newPassword" 
                      value={newPassword} 
                      onChange={(e) => setNewPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                    <input 
                      type="password" 
                      id="confirmPassword" 
                      value={confirmPassword} 
                      onChange={(e) => setConfirmPassword(e.target.value)} 
                      required 
                    />
                  </div>
                  
                  <input type="hidden" name="role" value="admin" />
                  {createAccountError && <div className="error-message">{createAccountError}</div>}
                  <button type="submit" className="add-account-btn">
                    <FontAwesomeIcon icon={faUserPlus} /> إنشاء مشرف جديد
                  </button>
                </form>
              </div>
            )}
            
            {accountSection === 'manageAccounts' && (
              <div className="manage-accounts">
                <h3>إدارة الحسابات</h3>
                <div className="accounts-list">
                  <table className="accounts-table">
                    <thead>
                      <tr>
                        <th>اسم المستخدم</th>
                        <th>البريد الإلكتروني</th>
                        <th>الدور</th>
                        <th>الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr key={index}>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>{user.role === 'owner' ? 'مالك' : user.role === 'admin' ? 'مشرف' : 'مستخدم'}</td>
                          <td className="account-actions">
                            <button 
                              className="edit-account-btn" 
                              onClick={() => handleEditAccount(user)}
                              title="تعديل"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button 
                              className="delete-account-btn" 
                              onClick={() => handleDeleteAccount(user.id)}
                              title="حذف"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

  // مكون تأثير تساقط النجوم
  const StarfallEffect = () => {
    const stars = [];
    const starCount = 8;
    const safeMargin = 10;
    const columns = 4;
    
    for (let i = 0; i < starCount; i++) {
      const size = ['small', 'medium', 'large'][Math.floor(Math.random() * 3)];
      const columnIndex = i % columns;
      const availableWidth = 100 - (safeMargin * 2);
      const columnWidth = availableWidth / columns;
      const left = safeMargin + (columnIndex * columnWidth) + (Math.random() * (columnWidth * 0.7));
      const top = -10;
      const delay = Math.random() * 3;
      const duration = 3 + Math.random() * 2;
      
      stars.push(
        <div
          key={i}
          className={`star star-${size}`}
          style={{
            left: `${left}%`,
            top: `${top}px`,
            animationDelay: `${delay}s`,
            animationDuration: `${duration}s`
          }}
        />
      );
    }
    
    return (
      <div className="starfall-container">
        {stars}
      </div>
    );
  };

  // مكون النجوم الصغيرة المتحركة في الأعلى
  const SmallFallingStarsLine = () => {
    const stars = [];
    const starCount = 16;
    const columns = 4;
    const rows = 3;
    
    for (let i = 0; i < starCount; i++) {
      const size = Math.random() < 0.7 ? 'tiny' : 'small';
      const safeMargin = 15;
      const columnIndex = i % columns;
      const availableWidth = 100 - (safeMargin * 2);
      const columnWidth = availableWidth / columns;
      const left = safeMargin + (columnIndex * columnWidth) + (Math.random() * (columnWidth * 0.5));
      const duration = 0.8 + Math.random() * 1.4;
      
      stars.push(
        <div
          key={i}
          className={`small-falling-star small-falling-star-${size}`}
          style={{
            left: `${left}%`,
            top: 0,
            animationDuration: `${duration}s`
          }}
        />
      );
    }
    
    return (
      <div className="static-stars-container">
        {stars}
      </div>
    );
  };

  // دالة تسجيل الخروج
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentUser');
    setActiveTab('accounts');
    setAccountSection('login');
    setLoginUsername('');
    setLoginPassword('');
    navigate('/');
  };

  // أضف هذه المتغيرات في بداية المكون
  const [newEmail, setNewEmail] = useState('');
  const [createAccountError, setCreateAccountError] = useState('');

  // أضف هذه الوظيفة قبل دالة renderAccountsContent
  const handleEditAccount = (user) => {
    // تنفيذ وظيفة تعديل الحساب
    setNewUsername(user.username);
    setDisplayName(user.displayName || '');
    setAccountType(user.role || 'admin');
    setSelectedPermissions(user.permissions || {
      createCategory: true,
      deleteCategory: true,
      createPost: true,
      deletePost: true
    });
    // انتقل إلى قسم التعديل
    setAccountSection('editAccount');
  };

  return (
    <div className={`admin-page ${darkMode ? 'dark-mode' : ''}`}>
      <div className="admin-container">
        {/* زر تبديل الشريط الجانبي للشاشات الصغيرة */}
        {isMobile && isLoggedIn && (
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        {/* الشريط الجانبي */}
        {isLoggedIn && (
          <div className={`admin-sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
            {/* إضافة سطر النجوم المتحركة في الأعلى */}
            <SmallFallingStarsLine />
            {/* إضافة تأثير النجوم المتساقطة */}
            <StarfallEffect />
            <div className="admin-logo-container no-border" style={{border: 'none'}}>
              <div className="logo-wrapper" style={{marginTop: '40px', marginBottom: '25px', border: 'none'}}>
                <div className="site-logo no-border" style={{border: 'none', borderBottom: 'none', boxShadow: 'none'}}>
                  <div className="logo-text no-border" style={{border: 'none', textDecoration: 'none'}}>
                    <span className="logo-gedid" style={{border: 'none'}}>Gedid</span>
                    <span className="logo-el" style={{border: 'none'}}>El</span>
                  </div>
                </div>
              </div>
            </div>
            
            <ul className="admin-tabs">
              {adminTabs
                .filter(tab => (isLoggedIn || tab.id === 'accounts') && tab.visible)
                .sort((a, b) => a.order - b.order)
                .map(tab => (
                  <li
                    key={tab.id}
                    className={`admin-tab ${activeTab === tab.id ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab.id)}
                    draggable
                    onDragStart={(e) => startDrag(e, 'sidebar', adminTabs.filter(t => t.visible).findIndex(t => t.id === tab.id))}
                    onDragOver={dragOver}
                    onDragLeave={dragLeave}
                    onDrop={(e) => drop(e, 'sidebar', adminTabs.filter(t => t.visible).findIndex(t => t.id === tab.id))}
                    onDragEnd={endDrag}
                  >
                    <FontAwesomeIcon
                      icon={tab.icon}
                      className="tab-icon"
                    />
                    <span className="tab-title">{tab.title}</span>
                    {editingTabs && (
                      <FontAwesomeIcon
                        icon={faGripVertical}
                        className="move-handle"
                      />
                    )}
                  </li>
                ))}
              
              {isLoggedIn && (
                <li className="admin-tab logout-tab" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="tab-icon" />
                  <span className="tab-title">تسجيل الخروج</span>
                </li>
              )}
            </ul>
          </div>
        )}
        
        <div className="admin-content" ref={contentRef}>
          {/* علامة التحميل */}
          {isLoading && <div className="loading-spinner"></div>}

          {/* محتوى التبويب النشط */}
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
