// وظيفة لتحويل الصورة إلى Base64
export const getBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

// وظيفة لحفظ الصورة في مجلد التحميلات
export const saveImage = async (file) => {
  try {
    // إنشاء اسم فريد للملف باستخدام الوقت الحالي
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}-${file.name}`;
    
    // نسخ الملف إلى مجلد التحميلات
    const formData = new FormData();
    formData.append('image', file);
    formData.append('fileName', fileName);

    // التحقق من وجود ملف صالح
    if (!file || !(file instanceof File)) {
      throw new Error('الملف غير صالح');
    }

    // إرسال الصورة إلى الخادم مع زيادة مهلة الاتصال
    const response = await fetch('/api/images/upload', {
      method: 'POST',
      body: formData,
      timeout: 30000 // زيادة وقت الانتظار إلى 30 ثانية
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'فشل في رفع الصورة');
    }

    const data = await response.json();
    return data.url; // سيكون المسار النسبي للصورة
  } catch (error) {
    console.error('خطأ في حفظ الصورة:', error);
    // إعادة ترجمة الخطأ بشكل أوضح
    if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
      throw new Error('فشل الاتصال بالخادم. يرجى التحقق من تشغيل الخادم والمحاولة مرة أخرى.');
    }
    throw error;
  }
};
