import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostCard from '../components/PostCard';
import '../styles/CategoryPage.css';
import { useTheme } from '../contexts/ThemeContext';

const CategoryPage = () => {
  const { categoryName } = useParams();
  const { darkMode } = useTheme();
  const [categoryPosts, setCategoryPosts] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState(null);

  // فك تشفير اسم القسم من URL
  const decodedCategoryName = decodeURIComponent(categoryName);

  // استرجاع المنشورات والتصنيفات من localStorage عند تحميل الصفحة
  useEffect(() => {
    const fetchData = () => {
      // استرجاع التصنيفات
      const savedCategories = localStorage.getItem('categories');
      const savedPosts = localStorage.getItem('posts');

      // البحث عن معلومات القسم
      if (savedCategories) {
        try {
          const parsedCategories = JSON.parse(savedCategories);
          const currentCategory = parsedCategories.find(cat => cat.name === decodedCategoryName);
          if (currentCategory) {
            setCategoryInfo(currentCategory);
          }
        } catch (error) {
          console.error('خطأ في استرجاع التصنيفات من localStorage:', error);
        }
      }

      // البحث عن منشورات القسم
      if (savedPosts && savedCategories) {
        try {
          const parsedPosts = JSON.parse(savedPosts);
          const parsedCategories = JSON.parse(savedCategories);
          
          // البحث عن معرف القسم الحالي
          const currentCategory = parsedCategories.find(cat => cat.name === decodedCategoryName);
          
          if (currentCategory) {
            // تصفية المنشورات حسب معرف القسم بدلاً من اسم القسم
            const filteredPosts = parsedPosts.filter(post => 
              post.category === currentCategory.id && 
              (post.status === 'published' || post.status === undefined)
            );
            
            // ترتيب المنشورات: المنشورات المميزة أولاً، ثم المنشورات الأخيرة من الأقدم إلى الأحدث
            filteredPosts.sort((a, b) => {
              if (a.isFeatured && !b.isFeatured) return -1;
              if (!a.isFeatured && b.isFeatured) return 1;
              return new Date(b.date) - new Date(a.date);
            });
            
            setCategoryPosts(filteredPosts);
          }
        } catch (error) {
          console.error('خطأ في استرجاع المنشورات من localStorage:', error);
        }
      }
    };

    // تنفيذ الدالة عند تحميل الصفحة
    fetchData();

    // إضافة مستمع للتغييرات في localStorage
    const handleStorageChange = () => {
      fetchData();
    };

    window.addEventListener('storage', handleStorageChange);

    // إضافة مستمع للتغييرات المحلية
    const interval = setInterval(fetchData, 1000);

    // تنظيف المستمعات عند إزالة المكون
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, [decodedCategoryName]);

  return (
    <div className={`category-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="category-page-header">
        <h1 className="category-page-title">
          {categoryInfo ? categoryInfo.name : decodedCategoryName}
        </h1>
      </div>
      <div className="category-page-content">
        <div className="posts-grid">
          {categoryPosts.length > 0 ? (
            categoryPosts.map(post => (
              <PostCard key={post.id} post={post} />
            ))
          ) : (
            <div className="no-posts-message">
              <p>لا توجد منشورات في هذا القسم حتى الآن.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
