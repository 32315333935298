import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import '../styles/PostCard.css';

const PostCard = ({ post }) => {
  const { id, title, excerpt, date, image, imageUrl, category, isFeatured } = post;
  
  // مسار الفئة
  const getCategoryUrl = (categoryName) => {
    switch (categoryName) {
      case 'التكنولوجيا':
        return '/category/technology';
      case 'الثقافة':
        return '/category/culture';
      case 'الاقتصاد':
        return '/category/economy';
      case 'الصحة':
        return '/category/health';
      default:
        return `/category/${encodeURIComponent(categoryName || '')}`;
    }
  };
  
  // مسار المنشور
  const postUrl = `/post/${id}`;
  
  // تحديد صورة افتراضية
  const defaultImage = '/logo192.png'; // استخدام شعار افتراضي
  
  // معالجة خطأ تحميل الصورة أو عدم وجود صورة
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  // تحديد مصدر الصورة المناسب بطريقة دقيقة
  const getImageSource = () => {
    // فحص وجود imageUrl وصلاحيته
    if (imageUrl && typeof imageUrl === 'string' && imageUrl.trim() !== '') {
      return imageUrl;
    } 
    // فحص وجود image وصلاحيته
    else if (image && typeof image === 'string' && image.trim() !== '') {
      return image;
    } 
    // في حالة عدم وجود صورة صالحة
    else {
      return defaultImage;
    }
  };

  return (
    <div className={`post-card ${isFeatured ? 'pinned' : ''}`}>
      {isFeatured && (
        <div className="pin-indicator" title="منشور مميز">
          <FontAwesomeIcon icon={faThumbtack} />
        </div>
      )}
      <Link to={postUrl} className="post-image">
        <img 
          src={getImageSource()} 
          alt={title || "منشور"} 
          onError={handleImageError}
        />
      </Link>
      <div className="post-content">
        <h3 className="post-title">
          <Link to={postUrl}>{title}</Link>
        </h3>
        <span className="post-date">
          {typeof date === 'string' && date.includes('T') 
            ? new Date(date).toLocaleDateString('ar-EG') 
            : date}
        </span>
        <p className="post-excerpt">{excerpt}</p>
        <Link to={postUrl} className="read-more">
          اقرأ المزيد
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
