import React, { useState, useEffect } from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { darkMode } = useTheme();
  const [showScrollButton, setShowScrollButton] = useState(false);
  
  // تتبع موضع التمرير لإظهار/إخفاء زر العودة إلى الأعلى
  useEffect(() => {
    const handleScroll = () => {
      // إظهار الزر عندما يتم التمرير لأسفل أكثر من 300 بكسل
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    
    // إضافة مستمع حدث التمرير
    window.addEventListener('scroll', handleScroll);
    
    // إزالة مستمع الحدث عند تفكيك المكون
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <div className="footer-links">
              <Link to="/about">من نحن</Link> | <Link to="/privacy">سياسة الخصوصية</Link>
            </div>
            <div className="footer-links footer-links-second-row">
              <Link to="/advertise">أعلن معنا</Link> | <Link to="/contact">تواصل معنا</Link>
            </div>
          </div>
          
          <div className="footer-center">
            <div className="footer-logo">
              <img 
                src="/logo/logo.png" 
                alt="شعار El Gedid" 
                className="footer-logo-img" 
              />
            </div>
          </div>
          
          <div className="footer-right">
            <div className="copyright">
              &copy; {currentYear} جميع الحقوق محفوظة
            </div>
          </div>
        </div>
        
        {showScrollButton && (
          <button className="scroll-to-top-btn" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </button>
        )}
      </footer>
    </>
  );
};

export default Footer;
